import React, { useEffect, useState } from 'react';
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import { onAccountFetchData } from "../../../../actions/actions";
import SimpleForm from "../../../forms/form";
import SlideModal from '../../../common/SlideModal';
import Popup from '../../../common/popup';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Table } from 'antd';

const AccountLocationDataEdit = (props) => {
    const [value, setValue] = useState([]);
    const [popup, setPopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        setValue(props.value ? props.value : null);
    }, [])

    const saveField = () => {
        setEditId(null);
        setPopup(false);
        props.onFieldSave(props.updateRoute, props.id, value, props.additionalData);
    }

    const onEditData = (editId) => {
        setPopup(true);
        setEditId(editId);
    }

    const onDeleteData = (editId) => {
        setDeletePopup(true);
        setEditId(editId);
    }

    const handleDeleteData = () => {
        client
            .get('/' + props.match.params.db + '/api/account-location/' + editId + '/delete')
            .then(response => {
                let data = value;
                let indx = data.indexOf(data.find(o => o.id === editId));
                if (indx > -1) {
                    data.splice(indx, 1);
                }
                setDeletePopup(false);
                setEditId(null);
                setValue(data);
                saveField();
                showAToast(response.data.success, 'baseSuccess', 'success');
            })
            .catch(err => {
                console.log(err)
                showAToast('Error', 'baseError', 'error');
            });
    }

    const getDataValue = () => {
        const columns = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        <span>
                            <EditOutlined
                                style={{ margin: 5 }}
                                onClick={() => onEditData(record.id)}  />
                            <DeleteOutlined
                                style={{ fontSize: 14 }}
                                onClick={() => onDeleteData(record.id)} />
                        </span>
                    )
                },
                width: 30,
                align: 'center',
            },
            {
                title: plainTranslate(props.settings.locale, 'Company'),
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: plainTranslate(props.settings.locale, 'Currency'),
                dataIndex: 'curr',
                key: 'curr'
            },
            {
                title: plainTranslate(props.settings.locale, 'Language'),
                dataIndex: 'lang',
                key: 'lang'
            },
            {
                title: plainTranslate(props.settings.locale, 'Terms'),
                dataIndex: 'terms',
                key: 'terms'
            },
            {
                title: plainTranslate(props.settings.locale, 'Tax class'),
                dataIndex: 'taxClass',
                key: 'taxClass'
            },
        ];
        return (
            // props.value.map((value, key) => {
            //     return <React.Fragment key={key}>
            //         <div className="line line-address" style={{ display: "block", paddingLeft: 20 }}>
            //             {value.company ? value.company + ',' : ''} 
            //             {value.curr ? value.curr + ',' : ''} 
            //             {value.lang ? value.lang + ',' : ''}
            //             {value.terms ? value.terms : ''}
            //             <EditOutlined
            //                 style={{ margin: 5 }}
            //                 onClick={() => onEditData(value.id)} 
            //                 />
            //             <DeleteOutlined
            //                 style={{ fontSize: 14 }}
            //                 onClick={() => onDeleteData(value.id)} 
            //                 />
            //         </div>
            //         {key < props.value.length - 1 &&
            //             <hr />
            //         }
            //     </React.Fragment>
            // })
            <Table dataSource={props.value} columns={columns} pagination={false} rowKey={record => record.id} scroll={{ x: 'max-content' }}/>
        );
    }

    const getNoData = () => {
        return (
            <div className="div-inline m-left15"><span
                className="text-italic" style={{ color: '#a0a0a0' }}>{plainTranslate(props.settings.locale, 'No account locations information added.')}</span></div>
        );
    }

    const onSuccessfulSave = () => {
        props.onAccountFetchData(props.db, props.accountId, false);
        setPopup(false);
    }

    const closePopup = () => {
        setPopup(false);
        setEditId(null);
    }

    return (
        <Card
            size="small"
            style={{ marginTop: 10 }}
            title={plainTranslate(props.settings.locale, 'Terms by companies')}
            extra={<PlusCircleOutlined onClick={() => setPopup(true)} />}>
            <div className="account-profile-other-information-more">
                <React.Fragment>
                    {props.value ? getDataValue() : getNoData()}
                </React.Fragment>
            </div>
            {popup &&
                <SlideModal onClose={closePopup} title={plainTranslate(props.settings.locale, 'Account locations')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    formRoute={props.match.params.db + '/api/account-location/'}
                                    saveRoute={editId ? props.match.params.db + '/api/account-location/' : props.match.params.db + '/api/account-location'}
                                    popup={true}
                                    isNew={true}
                                    onCancel={closePopup}
                                    match={{ params: { id: editId ? editId : 0 } }}
                                    refs={props.accountId}
                                    form={'initializeFromState'}
                                    onSuccessfulSave={onSuccessfulSave}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            {deletePopup &&
                <Popup locale={props.settings.locale}
                    title={plainTranslate(props.settings.locale, 'Delete account location')}
                    onClose={() => setDeletePopup(false)}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">

                                    <h3>
                                        <Translate locale={props.settings.locale} value="Are you sure?" />
                                    </h3>
                                    <button className="button-outlines green text-center"
                                        onClick={() => setDeletePopup(false)}>
                                        <Translate locale={props.settings.locale} value="No" />
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                        onClick={() => handleDeleteData()}>
                                        <Translate locale={props.settings.locale} value="Yes" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            }
        </Card>
    );
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAccountFetchData: (db, id, start) => dispatch(onAccountFetchData(db, id, start)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountLocationDataEdit));