import React, { Component } from 'react';
import Translate, { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import { Select } from 'antd';

class Attributes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            showButton: true
        };
        this.addNewRow = this.addNewRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        let values = this.props.input.value ? this.props.input.value : [];
        this.setState({
            values
        });
    }

    addNewRow() {
        let values = this.props.input.value ? this.props.input.value : [];
        let newItem = { category: '', name: '', type : '' };
        values.push(newItem);
        this.setState({
            values
        });
    }

    removeRow(key) {
        let values = this.state.values;
        values.splice(key, 1);

        this.setState({
            values
        });
    }

    onChange(value, type, key) {
        let values = this.state.values;
        values[key][type] = value;
        this.setState({
            values
        });
        this.props.input.onChange(values);
    }

    render() {
        return (
            <div>
                <div className='col-md-8 col-xs-12 no-gutter-m no-gutter'>
                    <span className="form-input-field tableCollection">
                        <table>
                            <thead style={{ border: "none" }}>
                                <tr>
                                    {this.props.field.fields && this.props.field.fields.length && this.props.field.fields.map((field, key) => {
                                        return <td className="text-left" key={key}>
                                            {plainTranslate(this.props.settings.locale, field.name)}
                                        </td>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.values && this.state.values.length > 0 && this.state.values.map((attribute, key) =>
                                    <tr key={key}>
                                        {this.props.field.fields && this.props.field.fields.length && this.props.field.fields.map((field, ind) => {
                                            return <>
                                                {field.type == "text" &&
                                                    <td key={ind}>
                                                        <input className="form-control form-stage" defaultValue={attribute[field.name.toLowerCase()]} onChange={(e) => this.onChange(e.target.value, field.name.toLowerCase(), key)} />
                                                    </td>
                                                }
                                                {field.type == "select" &&
                                                    <td key={ind}>
                                                        <Select options={field.options} showSearch filterOption={true} onChange={(e) => this.onChange(e, field.name.toLowerCase(), key)} optionFilterProp="label" style={{ width: "100%" }} value={attribute[field.name.toLowerCase()]}>
                                                        </Select>
                                                    </td>
                                                }
                                            </>
                                        })}
                                        <td className={'right'}>
                                            <span onClick={() => this.removeRow(key)}>
                                                <i className='ion-android-close' title={plainTranslate(this.props.settings.locale, 'Remove row')}></i></span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                {this.state.showButton &&
                                    <tr className="text-center">
                                        <th colSpan="3">
                                            <a className="button-outline grey text-uppercase" onClick={this.addNewRow}>
                                                {plainTranslate(this.props.settings.locale, 'New row')}
                                            </a>
                                        </th>
                                    </tr>
                                }
                            </tfoot>
                        </table>
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);
