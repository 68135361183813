import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { plainTranslate } from "../common/translate";
import { withRouter } from 'react-router-dom'
import { Drawer, Button, Menu } from 'antd';
import { connect } from 'react-redux';
//import 'antd/dist/antd.css';
import {
  onSidebarMount,
} from '../../actions/actions';

const { SubMenu } = Menu;



class MenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      placement: 'left',
      openKeys: []
    };
    this.showDrawer = this.showDrawer.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this.onTextClick = this.onTextClick.bind(this);
  }
  rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub12'];

  showDrawer() {
    this.setState({
      visible: true,
    });
  }

  onClose() {
    this.setState({
      visible: false, openKeys: []
    });
  }

  onIconClick(url) {
    this.props.history.push(url);
    this.onClose();
  }

  onTextClick(url) {
    this.props.history.push(url);
    this.onClose();
  }

  componentDidMount() {
    this.props.onSidebarMount('/' + this.props.db + '/api/settings/sidebar');
    require('antd/dist/antd.css');
  }

  onOpenChange(openKeys) {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    let showReportsTab = false;
    if (this.props.settings.bundles.reportsbundle && this.props.settings.reports && this.props.settings.reports.predefinedReports) {
      this.props.settings.reports.predefinedReports.map((report, key) => {
        if (report.id === 'stockOnHand' || report.id === 'payments') {
          showReportsTab = true;
        }
      });
    }

    const urlParams = new URLSearchParams(this.props.location.search);
    const locId = urlParams.get('locId');
    const formId = urlParams.get('type');

    if (locId !== 'undefined' && locId !== null) {
      localStorage.setItem('locId', locId);
    }

    if (formId !== 'undefined' && formId !== null) {
      localStorage.setItem('formid', formId);
    }

    let saleLink = '/' + this.props.match.params.db + '/pos/transactions/new?locId=' + localStorage.locId;

    if (localStorage.formid !== 'undefined') {
      saleLink = saleLink + '&type=' + localStorage.formid;
    }

    const settings = this.props.settings;

    return (
      <div className="left-mobileMenu">
        <i className="fa fa-bars" aria-hidden="true" onClick={this.showDrawer} />
        <Drawer
          title={<span> <span>{plainTranslate(settings.locale, 'Menu')}</span> <i onClick={this.onClose} style={{ float: "right" }} className="fa fa-close" aria-hidden="true"></i> </span>}
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className="mobileMenu"
            selectedKeys={[]}
          >
            {this.props.settings?.customMenu?.map((item, key) => {
              return <>
                {item.children ?
                  <SubMenu key={'sub' + item.label + key} title={<span>{plainTranslate(settings.locale, item.label)}</span>}>
                    {item.children && item.children.map((list, key) => {
                      return <>
                        {list.children ?
                          <SubMenu key={'sub' + list.label + key} title={<span>{plainTranslate(settings.locale, list.label)}</span>}>
                            {list?.children.map((child, ind) => {
                              return <Menu.Item key={child.label + ind}>
                                <Link onClick={this.onClose} className="draggable" id={child.label}
                                  title={plainTranslate(settings.locale, child.label)}
                                  to={child.value}>
                                  <span>{plainTranslate(settings.locale, child.label)}</span>
                                </Link>
                              </Menu.Item>
                            })}
                          </SubMenu>
                          :
                          <Menu.Item key={list.label + key}>
                            <Link onClick={this.onClose} className="draggable" id={list.label}
                              title={plainTranslate(settings.locale, list.label)}
                              to={list.value}>
                              <span>{plainTranslate(settings.locale, list.label)}</span>
                            </Link>
                          </Menu.Item>
                        }
                      </>
                    })
                    }
                  </SubMenu>
                  :
                  <Menu.Item key={item.label + key}>
                    <Link onClick={this.onClose} className="draggable" id="dashboard" to={item.value} title={plainTranslate(settings.locale, item.label)}>
                      <span>{plainTranslate(settings.locale, item.label)}</span>
                    </Link>
                  </Menu.Item>
                }
              </>
            }
            )}
            <SubMenu key="sub12" title={<span>{plainTranslate(settings.locale, 'My profile')}</span>}>
              {
                settings.user &&
                <Menu.Item key="38">
                  <Link onClick={this.onClose} className="draggable" id="profile"
                    to={'/' + this.props.db + '/user/profile'}
                    title={plainTranslate(settings.locale, 'Profile')}>
                    <span>{plainTranslate(settings.locale, 'Profile')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                <Menu.Item key="39">
                  <Link onClick={this.onClose} className="draggable" id="subscription"
                    to={'/' + this.props.db + '/subscription-details'}
                    title={plainTranslate(settings.locale, 'Subscription and Billing')}>
                    <span>{plainTranslate(settings.locale, 'Subscription and Billing')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                <Menu.Item key="40">
                  <Link onClick={this.onClose} className="draggable" id="modules"
                    to={'/' + this.props.db + '/modules'}
                    title={plainTranslate(settings.locale, 'Modules and Add-ons')}>
                    <span>{plainTranslate(settings.locale, 'Modules and Add-ons')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                <Menu.Item key="41">
                  <Link onClick={this.onClose} className="draggable" id="settings-general"
                    to={'/' + this.props.db + '/settings/general'}
                    title={plainTranslate(settings.locale, 'Settings')}>
                    <span>{plainTranslate(settings.locale, 'Settings')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                <Menu.Item key="42">
                  <Link onClick={this.onClose} className="draggable" id="stats"
                    to={'/' + this.props.db + '/stats'}
                    title={plainTranslate(settings.locale, 'Statistics')}>
                    <span>{plainTranslate(settings.locale, 'Statistics')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.bundles && settings.user && settings.bundles.appuserbundle && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                <Menu.Item key="43">
                  <Link onClick={this.onClose} className="draggable" id="users"
                    to={'/' + this.props.db + '/administration/users-v2/admin'}
                    title={plainTranslate(settings.locale, 'Users')}>
                    <span>{plainTranslate(settings.locale, 'Users')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.user &&
                <Menu.Item key="44">
                  <Link onClick={this.onClose} className="draggable" id="logout"
                    to={'/' + this.props.db + '/logout'}
                    title={plainTranslate(settings.locale, 'Log Out')}>
                    <span>{plainTranslate(settings.locale, 'Log Out')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                settings.user &&
                <Menu.Item key="45">
                  <Link onClick={this.onClose} className="draggable" id="workspace-signin"
                    to={'/' + this.props.db + '/workspace/signin'}
                    title={plainTranslate(settings.locale, 'Sign in to another workspace')}>
                    <span>{plainTranslate(settings.locale, 'Sign in to another workspace')}</span>
                  </Link>
                </Menu.Item>
              }
              {
                !settings.user &&
                <Menu.Item key="46">
                  <Link onClick={this.onClose} className="draggable" id="user-signin"
                    to={'/' + this.props.db + '/user/signin'}
                    title={plainTranslate(settings.locale, 'Sign in')}>
                    <span>{plainTranslate(settings.locale, 'Sign in')}</span>
                  </Link>
                </Menu.Item>
              }
            </SubMenu>
          </Menu>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onSidebarMount: (route) => dispatch(onSidebarMount(route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuMobile));