import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { onDocumentProfileFetchData, onEditOptionsLoad, onTabsLoad } from '../../actions/actions';
import { Link } from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import GeneralInformation from './general-information';
import PaymentsInfo from './payments-info';
import InventoryInfo from './inventory-receipts';
import ReversedDocs from './reversed-docs';
import InvoiceSalesInfo from './invoice-sales';
import SlideModal from '../common/SlideModal';
import NewForm from "../redux-forms/form";
import './invoiceProfile.css';
import Select from 'react-select';
import { Table, Button, Tooltip, Menu, Dropdown, Drawer } from 'antd';
import { EditOutlined, FormOutlined, FilePdfOutlined, SnippetsOutlined, DropboxOutlined, ScheduleOutlined, FileDoneOutlined, SendOutlined, ExportOutlined, LinkOutlined } from '@ant-design/icons'
import 'antd/lib/table/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import 'antd/lib/icon/style/index.css';
import 'antd/lib/dropdown/style/index.css';
import 'antd/lib/tabs/style/index.css';
import 'antd/lib/tooltip/style/index.css';
import 'antd/lib/card/style/index.css';
import 'antd/lib/grid/style/index.css';
import { Timeline } from 'antd';
import Popup from "../common/popup";
import LoggedChanges from './logged-changes';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import SharesInfo from './shares-info';
import EditablePrint from '../custom-pages/editablePrint.jsx';
import Form from "../redux-forms/form";

// const { TabPane } = Tabs;

class SalesProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            saleTemplate: null,
            invoiceTemplate: null,
            active: 'sale',
            confirmPopup: false,
            record: false,
            // showAccountModal: false,
            // showAddressModal: false,
            // showBillingModal: false,
            showActionsDropdown: false,
            checkedSaleLines: [],
            emailPopup: false,
            invoiceTemplateCc: null,
            accountingTemplate: null,
            disableButton: false,
            typeEmail: null
            //profileTab: 'sale'
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.update = this.update.bind(this);
        this.createManufacturing = this.createManufacturing.bind(this);
        this.confirmCreateManufacturing = this.confirmCreateManufacturing.bind(this);
        // this.openModal = this.openModal.bind(this);
        this.updateData = this.updateData.bind(this);
        this.setDataToSale = this.setDataToSale.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.changeDefaultTemplate = this.changeDefaultTemplate.bind(this);
        this.createAccounting = this.createAccounting.bind(this);
    }

    componentDidMount() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/sale/' + this.props.match.params.id + '/profile-info')
        .then(() => {
            let roles = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.roles : {};
            let profileTab = null;
            if(roles.sales && typeof roles.sales !== 'undefined' && roles.sales.hasView){
                profileTab = 'sale';
            }
            const urlSearchParams = new URLSearchParams(window.location.search);
            const myParam = urlSearchParams.get('tab');

            if((profileTab === null && roles.invoices.hasView) || (roles.invoices.hasView && myParam==='invoice')){
                profileTab = 'invoice';
            }
            
            if(profileTab == "sale"){
                this.setState({ docId: this.props.salesProfileInformation.invoiceProfileInfo.sale.id });
            }else if(profileTab == "invoice"){
                this.setState({ docId: this.props.salesProfileInformation.invoiceProfileInfo && this.props.salesProfileInformation.invoiceProfileInfo.invoice && this.props.salesProfileInformation.invoiceProfileInfo.invoice.secureId });
            }
            this.setState({ profileTab: profileTab, saleTemplate: this.props.salesProfileInformation.invoiceProfileInfo.defaultTemplateSale.id })
            this.setState({ invoiceTemplate: this.props.salesProfileInformation.invoiceProfileInfo.defaultTemplateInvoice.id, invoiceTemplateCc: this.props.salesProfileInformation.invoiceProfileInfo.defaultTemplateInvoice.cc })
            
            this.setState({ defaultTemplate: this.props.salesProfileInformation.invoiceProfileInfo.invoice ? this.props.salesProfileInformation.invoiceProfileInfo.invoice.defaultTemplate : null});
        });
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.setState({ active: localStorage.getItem('activeSaleProfileTab') ? localStorage.getItem('activeSaleProfileTab') : 'sale' });
       
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.update();
        }
    }

    handleTemplateChange(e) {
        if(this.state.profileTab === 'sale'){
            this.setState({ saleTemplate: e.value });
        }else if(this.state.profileTab === 'invoice'){
            this.setState({ invoiceTemplate: e.value });
        }
    }

    update() {
        this.setState({showLoader: true});
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/sale/' + this.props.match.params.id + '/profile-info')
        .then(response => {
            this.setState({showLoader: false});
            this.setState({ disableButton: false });
        })
    }

    changeTab(tab) {
        localStorage.setItem('activeSaleProfileTab', tab);

        this.setState({ active: tab });
    }

    changeProfileTab(tab) {
        if(tab === 'sale'){
            this.setState({ saleTemplate: this.props.salesProfileInformation.invoiceProfileInfo.defaultTemplateSale.id, docId: this.props.salesProfileInformation.invoiceProfileInfo.sale.id })
        }else if(tab === 'invoice'){
            this.setState({ invoiceTemplate: this.props.salesProfileInformation.invoiceProfileInfo.defaultTemplateInvoice.id, docId: this.props.salesProfileInformation.invoiceProfileInfo.invoice.secureId })
        }
        this.setState({ profileTab: tab });
    }

    confirmCreateManufacturing(record) {
        this.setState({
            confirmPopup: true,
            record: record
        })
    }
    
    createManufacturing(record) {
        let addVal = {};
        addVal['itId'] = record.itId;
        addVal['qty'] = record.quantity;
        addVal['custId'] = this.props.salesProfileInformation.invoiceProfileInfo.sale.billingId;
        addVal['locId'] = this.props.salesProfileInformation.invoiceProfileInfo.sale.locId;
        addVal['accId'] = this.props.salesProfileInformation.invoiceProfileInfo.sale.accId;
        addVal['name'] = this.props.salesProfileInformation.invoiceProfileInfo.sale.name;

        client.post('/' + this.props.match.params.db + '/api/production/transfer/sale/line', addVal)
        .then(response => response.data)
        .then(response => {
            if (response['success']) {
                showAToast(response.success, 'baseSuccess', 'success');
            } else {
                showAToast(response.errors, 'baseError', 'error');
            }
            this.setState({
                confirmPopup: false,
                record: false
            })
        })
    }

    createAccounting(id) {
        this.setState({ disableButton: true });
        let addVal = {};
        addVal['body'] = id;
        client.post('/' + this.props.match.params.db + '/api/sales/transfer/to/accounting/' + this.state.accountingTemplate, addVal)
        .then(response => response.data)
        .then(response => {
            if (response['success']) {
                showAToast(response.success, 'baseSuccess', 'success');
            } else {
                showAToast(response.errors, 'baseError', 'error');
            }
            this.setState({
                accountingTemplate: false
            });
            this.update();
        })
    }

    updateData(modal) {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/sale/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({ [modal]: false });
        });
    }

    setDataToSale(type, id, saleId) {
        let addVal = {};
        let jsonData = [];
        addVal[type] = id;
        jsonData.push(JSON.stringify(addVal));

        client.post('/' + this.props.match.params.db + '/api/online/sale/' + saleId + '/set/account/data', jsonData)
        .then(response => response.data)
        .then(response => {
            if (response['success']) {
                this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/sale/' + this.props.match.params.id + '/profile-info');
                showAToast(response.success, 'baseSuccess', 'success');
            } else {
                showAToast(response.errors, 'baseError', 'error');
            }
        }) 
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('touchend', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.className !== "mobileMenuLink") {
            this.setState({ showActionsDropdown: false });
        }
    }

    changeDefaultTemplate(value){
        client
        .get('/' + this.props.match.params.db + '/api/invoice/' + this.props.salesProfileInformation.invoiceProfileInfo.invoice.secureId + '/template/' + value + '/change')
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
            }
            this.setState({defaultTemplate: value});
        });
    }

    sentEmailTemplate = (type) => {
        this.setState({typeEmail: type});
        this.setState({emailPopup: true});
    }

    onCopyLink(link){
        navigator.clipboard.writeText(window.location.origin +link);
        showAToast(plainTranslate(this.props.settings.locale, 'Invoice link copied!'), 'succSettings', 'success');
    }

    // checkboxSelect = (e, id) => {
    //     let checkedSaleLines = [];
    //     if (id == 'all') {
    //         if ( e.target.checked == true) {
    //             let lines = this.props.salesProfileInformation.invoiceProfileInfo.sale.lines;
    //             for (let i=0; i<lines.length; i++) {
    //                 if (lines[i]['itemCountStock'] == 1) {
    //                     checkedSaleLines.push(lines[i]['id']);
    //                 }
    //             }
    //         } else {
    //             checkedSaleLines = [];
    //         }
    //     } else {
    //         checkedSaleLines = this.state.checkedSaleLines;
    //         if (e.target.checked == true) {
    //             checkedSaleLines.push(id);
    //         } else {
    //             let index = checkedSaleLines.indexOf(id);
    //             if (index > -1) {
    //                 checkedSaleLines.splice(index, 1);
    //             }
    //         }
    //     }
    //     this.setState({ checkedSaleLines: checkedSaleLines });
    // }

    render() {
        let sale = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.sale : {};
        let saleLines = sale ? sale.lines : [];
        let showDeliveryLink = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.showDeliveryLink : null;
        let payments = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.payments : [];
        let invoice = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.invoice : {};
        let inventories = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.inventories : [];
        let saleTemplates = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.saleTemplates : [];
        let invoiceTemplates = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.invoiceTemplates : [];
        let paymentTypes = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.paymentTypes : [];
        let currencySign = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.currency : null;
        let reversedSales = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.reversedSales : [];
        let paymentMethods = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.paymentMethods : [];
        let stages = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.stages : [];
        let roles = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.roles : {};
        let taskForms = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.taskForms : [];
        let orderedItems = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.orderedItems : [];
        // let cartSale = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.cartSale : {};
        let listId = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.listId : null;
        // let isOnlineSale = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.isOnlineSale : false;
        let changes = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.changes : [];
        let users = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.users : [];
        let sherableLink = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.sherableLink : null;
        let shares = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.shares : null;
        let linkedDocuments = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.linkedDocuments : [];
        let paymentStagesOptions = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.paymentStagesOptions : [];
        let inventoryStagesOptions = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.inventoryStagesOptions : [];
        let itemRequestStagesOptions = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.itemRequestStagesOptions : [];
        let invoiceSales = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.invoiceSales : [];
        let editTemplatesSale = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.editTemplatesSale : [];
        let editTemplatesInvoice = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.editTemplatesInvoice : [];
        let emailSettings = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.emailSettings : null; 
        let ledger = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.ledger : null; 
        let accountingOptions = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.accOptions : null; 
        let isAccounting = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.isAccounting : null; 

        if (this.state.showLoader || !this.props.salesProfileInformation.invoiceProfileInfo) {
            return <FormLoader />
        }

        const salesColumns = [
            (this.props.settings.plan.production && roles.production && roles.production.hasCreate) ? {
                'title': '',
                'key': 'operation',
                'fixed': 'left',
                'width': 20,
                'hide': true,
                render: (record) => <i className="fa fa-industry" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create production')} onClick={() => this.confirmCreateManufacturing(record)}></i>
            } : {},
            // ((this.props.settings.plan.purchasing || this.props.settings.plan.itemRequests) && cartSale && Object.keys(cartSale).length !== 0) ? {
            //     'title': '',
            //     'key': 'operation',
            //     'fixed': 'left',
            //     'width': 30,
            //     'hide': true,
            //     render: (text, record) => (
            //     <span>    
            //         {record.itemCountStock != 8 && record.itemCountStock != 9 ?
            //         <Link to={"/" + this.props.match.params.db + "/item-requests/new?item=" + record.itId + "&qty=" + record.quantity + "&location=" + sale.locId + "&saleLine=" + record.id}>
            //             <i className="fa fa-truck" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create item request')}></i>
            //         </Link>
            //         : ''}
            //     </span>
            //     )  
            // } : {},
            // ((this.props.settings.plan.purchasing || this.props.settings.plan.itemRequests) && cartSale && Object.keys(cartSale).length !== 0) ? {
            //     'title': <Checkbox onChange={(e) => this.checkboxSelect(e, 'all')}></Checkbox>,
            //     'key': 'choose',
            //     'fixed': 'left',
            //     'width': 30,
            //     'hide': true,
            //     render: (text, record) => (
            //         <span> 
            //             {record.itemCountStock != 8 && record.itemCountStock != 9 ?
            //                 <Checkbox checked={this.state.checkedSaleLines.includes(record.id)} onChange={(e) => this.checkboxSelect(e, record.id)}></Checkbox>
            //             : ''}
            //         </span> 
            //     )  
            // } : {},
            {
                'dataIndex': 'lineNo',
                'title': plainTranslate(this.props.settings.locale, 'Line'),
                'width': 60,
            },
            {
                'dataIndex': 'itName',
                'title': plainTranslate(this.props.settings.locale, 'Item'),
                'width': 150,
            },
            {
                'dataIndex': 'itemDescription',
                'title': plainTranslate(this.props.settings.locale, 'Description'),
                'width': 250,
            },
            {
                'dataIndex': 'batchName',
                'title': plainTranslate(this.props.settings.locale, 'Batch'),
                'width': 120,
            },
            {
                'dataIndex': 'measName',
                'title': plainTranslate(this.props.settings.locale, 'Measure'),
                'width': 120,
            },
            {
                'dataIndex': 'quantity',
                'title': plainTranslate(this.props.settings.locale, 'Quantity'),
                'width': 120,
            },
            {
                'dataIndex': 'quantityReserved',
                'title': plainTranslate(this.props.settings.locale, 'Reserved'),
                'width': 120,
            },
            {
                'dataIndex': 'quantityProcessed',
                'title': plainTranslate(this.props.settings.locale, 'Processed'),
                'width': 120,
            },
            {
                'dataIndex': 'quantityAwaiting',
                'title': plainTranslate(this.props.settings.locale, 'Awaiting'),
                'width': 120,
            },
            {
                'dataIndex': 'quantityCancelled',
                'title': plainTranslate(this.props.settings.locale, 'Cancelled'),
                'width': 120,
            },
            {
                'dataIndex': 'quantityOrdered',
                'title': plainTranslate(this.props.settings.locale, 'Ordered'),
                'width': 120,
            },
            {
                'dataIndex': 'quantityReversed',
                'title': plainTranslate(this.props.settings.locale, 'Reversed'),
                'width': 120,
            },
            {
                'dataIndex': 'unitPriceOriginal',
                'title': plainTranslate(this.props.settings.locale, 'Unit Price'),
                'width': 120,
            },
            {
                'dataIndex': 'unitPrice',
                'title': plainTranslate(this.props.settings.locale, 'Unit Price'),
                'width': 120,
            },
            {
                'dataIndex': 'amountDiscount',
                'title': plainTranslate(this.props.settings.locale, 'Discount'),
                'width': 120,
            },
            {
                'dataIndex': 'amountBase',
                'title': plainTranslate(this.props.settings.locale, 'Base amount'),
                'width': 120,
            },
            {
                'dataIndex': 'amountTax',
                'title': plainTranslate(this.props.settings.locale, 'Tax'),
                'width': 120,
            },
            {
                'dataIndex': 'amountTotal',
                'title': plainTranslate(this.props.settings.locale, 'Total'),
                'width': 120,
            },
        ];

        const lineColumns = [
            {
                'dataIndex': 'productName',
                'title': plainTranslate(this.props.settings.locale, 'Product name'),
                'width': 50,
            },
            {
                'dataIndex': 'itemName',
                'title': plainTranslate(this.props.settings.locale, 'Item'),
                'width': 50,
            },
            {
                'dataIndex': 'quantity',
                'title': plainTranslate(this.props.settings.locale, 'Quantity'),
                'width': 20,
            },
            {
                'dataIndex': 'price',
                'title': plainTranslate(this.props.settings.locale, 'Price'),
                'width': 20,
            },
        ];

        const itemRequestColumns = [
            {
                'dataIndex': 'itemNo',
                'title': plainTranslate(this.props.settings.locale, 'Item'),
                render: (text, record) => roles.administration && roles.administration.hasView
                    ? <Link to={'/' + this.props.match.params.db + '/item/' + record.itemId + '/profile'}>{record.itemNo}</Link>
                    : <span> {record.itemNo} </span>,
            },
            {
                'dataIndex': 'itemName',
                'title': plainTranslate(this.props.settings.locale, 'Description'),
            },
            {
                'dataIndex': 'quantity',
                'title': plainTranslate(this.props.settings.locale, 'Ordered quantity'),
                render: (text, record) => (
                    <span>
                        <p className='text-red'>{record.quantity}</p>
                    </span>
                ),
            },
            {
                'dataIndex': 'quantity',
                'title': plainTranslate(this.props.settings.locale, 'Received Quantity'),
                render: (text, record) => (
                    <span>
                        <p className='text-red'>{record.receivedQty}</p>
                    </span>
                ),
            },
            {
                'dataIndex': 'requestNo',
                'title': plainTranslate(this.props.settings.locale, '#'),
            },
            {
                'dataIndex': 'requestDate',
                'title': plainTranslate(this.props.settings.locale, 'Date'),
            },

            {
                'dataIndex': 'status',
                'title': plainTranslate(this.props.settings.locale, 'Status'),
            },
            {
                'dataIndex': 'locationFrom',
                'title': plainTranslate(this.props.settings.locale, 'Location from'),
            },
            {
                'dataIndex': 'locationTo',
                'title': plainTranslate(this.props.settings.locale, 'Location to'),
            },
        ];

        {/*const expandedRow = row => {
        let subTable = row.subRequest;
        return <Table
                    rowKey={record => record.id}
                    columns={itemRequestColumns}
                    dataSource={subTable}
                    pagination={false}
            />

      };*/}
        const expandedRow = row => {
            let subTable = row.subRequest;
            let color = 'yellow';
            if (subTable.length > 0) {
                return <Timeline mode={'left'}>
                    {subTable.map((record, key) => {
                        if (record.status == "Actual") {
                            color = 'green';
                        } else if (record.status == "Draft") {
                            color = 'blue';
                        } else if (record.status == "Closed") {
                            color = 'red';
                        } else if (record.status == 'On the move') {
                            color = 'purple';
                        }
                        return <React.Fragment>
                            <Timeline.Item color={color} label={<h5 style={{padding: 15}}>{record.locationFrom}</h5>} style={{ paddingLeft: 20}}>
                                <p>
                                    {record.locationFrom !== '' ?
                                        <React.Fragment>
                                            {plainTranslate(this.props.settings.locale, 'From')} <strong>{record.locationFrom}</strong>
                                        </React.Fragment> : ''
                                    }
                                    {plainTranslate(this.props.settings.locale, ' To')} <strong>{record.locationTo}</strong> | {record.date}
                                </p>
                                <p>{plainTranslate(this.props.settings.locale, 'Quantity')}: {record.quantity}</p>
                                <p>{plainTranslate(this.props.settings.locale, 'Status')}: {record.stage}</p>
                                {record.type == 'transfer' &&
                                    <p>{plainTranslate(this.props.settings.locale, 'Transfer')}: {record.no}</p>
                                }
                                {record.type == 'purchase' &&
                                    <p>{plainTranslate(this.props.settings.locale, 'Purchase')}: {record.no}</p>
                                }
                                {record.type == 'request' &&
                                    <p>{plainTranslate(this.props.settings.locale, 'Request')}: {record.no}</p>
                                }
                            </Timeline.Item>
                        </React.Fragment>
                    }
                    )}
                </Timeline>
            } else {
                return <p>{plainTranslate(this.props.settings.locale, 'No data')}</p>
            }
        };
        const menu = (
            <Menu>
                {roles.sales && roles.sales.hasEdit === true &&
                    <Menu.Item onClick={() => this.setState({ showActionsDropdown: !this.state.showActionsDropdown })}>
                        <Link to={"/" + this.props.match.params.db + "/sales/" + this.props.match.params.id + '/edit'} className="mobileMenuLink">
                            {plainTranslate(this.props.settings.locale, 'Edit')}
                        </Link>
                    </Menu.Item>
                }
                <Menu.Item onClick={() => this.setState({ showActionsDropdown: !this.state.showActionsDropdown })}>
                {this.state.profileTab === 'sale' && this.state.saleTemplate && 
                    <Link to={'/' + this.props.match.params.db + '/sales/' + this.props.match.params.id + '/template/' + this.state.saleTemplate + '/export.pdf'} target="_blank" className="mobileMenuLink">
                        {plainTranslate(this.props.settings.locale, 'Download')}
                    </Link>
                }
                {this.state.profileTab === 'invoice' && this.state.invoiceTemplate && 
                    <Link to={'/' + this.props.match.params.db + '/invoicing/invoice/' + invoice.secureId + '/template/' + this.state.invoiceTemplate + '/export.pdf'} target="_blank" className="mobileMenuLink">
                        {plainTranslate(this.props.settings.locale, 'Download')}
                    </Link>
                }
                </Menu.Item>
                {showDeliveryLink &&
                    <Menu.Item onClick={() => this.setState({ showActionsDropdown: !this.state.showActionsDropdown })}>
                        <Link to={"/" + this.props.match.params.db + "/delivery/new?ids=" + this.props.match.params.id} className="mobileMenuLink">
                            {plainTranslate(this.props.settings.locale, 'Create delivery')}
                        </Link>
                    </Menu.Item>
                }
            </Menu>
        );
        let menuOptions = null;
        if (taskForms && taskForms.length) {
            menuOptions = (
                <Menu>
                    {taskForms.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({ newTaskPopup: true, formId: option.value })}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        let editTemplatesSaleOptions = [];
        if (editTemplatesSale) {
            editTemplatesSaleOptions = (
                <Menu>
                    {editTemplatesSale.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({ showEditTemplate: true, templateId: option.value })}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        let editTemplatesInvoiceOptions = [];
        if (editTemplatesInvoice) {
            editTemplatesInvoiceOptions = (
                <Menu>
                    {editTemplatesInvoice.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({ showEditTemplate: true, templateId: option.value })}>
                                    {option.label}
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        } 


        let saleTemplatesOptions = [];
        if (saleTemplates) {
            saleTemplatesOptions = (
                <Menu>
                    {saleTemplates.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a href={'/'+this.props.match.params.db + '/sales/' + this.props.match.params.id + '/template/' + option.value + '/export.pdf'} target="_blank">
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        let invoiceTemplatesOptions = [];
        if (invoiceTemplates) {
            invoiceTemplatesOptions = (
                <Menu>
                    {invoice && invoiceTemplates.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a href={"/" + this.props.match.params.db + "/invoicing/invoice/"+invoice.secureId+'/template/'+ option.value +'/export.pdf'} target="_blank">
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        let publicInvoiceOptions = [];
        if (invoiceTemplates) {
            publicInvoiceOptions = (
                <Menu>
                    {invoice && invoiceTemplates.map((option, i) => {
                            return <Menu.Item key={i}>
                                <span style={{cursor:'pointer'}} onClick={() => this.onCopyLink("/" + this.props.match.params.db + "/public/invoice/"+invoice.cc+'/template/'+ option.cc)}>{plainTranslate(this.props.settings.locale, option.label)}</span>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        let defaultInvoiceTemplateOptions = [];
        if (invoiceTemplates) {
            defaultInvoiceTemplateOptions = (
                <Menu>
                    {invoiceTemplates.map((option, i) => {
                            return <Menu.Item key={i} className={this.state.defaultTemplate === option.value ? "active" : ""}>
                                <a onClick={() => this.changeDefaultTemplate(option.value)}>
                                    {option.label}
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        let accOptionsMenu;
        if (accountingOptions) {
            accOptionsMenu = (
                <Menu>
                   {accountingOptions.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({ accountingTemplate: option['id'] })}>
                                    <Translate locale={this.props.settings.locale} value={option['name']} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }

        return (
            <div className="invoice-profile item-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        {this.props.gridPopup && <a style={{marginRight: 20, float:"right"}} href={"/" + this.props.match.params.db + '/sale/'+ this.props.match.params.id +'/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{fontSize: 15, marginLeft:5}}/></a>}
                    </div>
                    <div className="col-lg-12 col-md-12 col-xs-12" style={{ display: window.innerWidth > 780 ? "flex" : "block", alignItems: "center" }}>
                        <div className="profile-breadcrumb col-md-4">
                            {!this.props.gridPopup && roles && roles.sales && roles.sales.hasView &&
                                <Link to={"/" + this.props.match.params.db + "/sales-v2"}>
                                    <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Sales')}
                                </Link>
                            }
                            {!this.props.gridPopup && roles && roles.sales && !roles.sales.hasView && roles.invoices.hasView &&
                                <Link to={"/" + this.props.match.params.db + "/invoices/"}>
                                    <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Invoices')}
                                </Link>
                            }
                        </div>
                        {invoice &&
                            <div className="account-profile-tabs col-md-8" style={{ marginTop: "12px" }}>
                                <div className="nav nav-tabs" id="tabBtn">
                                    <div className="col-md-12 basic no-gutter document-profile-tabs">
                                        {roles.sales.hasView &&
                                            <button className={this.state.profileTab == 'sale' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeProfileTab('sale')}>
                                                {plainTranslate(this.props.settings.locale, 'Sale')}
                                            </button>
                                        }
                                        {roles.invoices.hasView &&
                                            <button className={this.state.profileTab == 'invoice' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeProfileTab('invoice')}>
                                                {plainTranslate(this.props.settings.locale, 'Invoice')}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                        {this.state.profileTab === 'sale' && window.innerWidth < 780 &&
                            <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                                {roles.sales && roles.sales.hasEdit === true &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                        <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Link to={"/" + this.props.match.params.db + "/sales/" + this.props.match.params.id + '/edit'}>
                                                 <EditOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                }
                                {roles.sales && roles.sales.hasEdit===true &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit-beta')}>
                                        <Button type="default" ghost style={{color: 'green', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Link to={"/" + this.props.match.params.db + "/sale-new/"+this.props.match.params.id+'/edit'}>
                                                <FormOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                }
                                {this.state.saleTemplate && <>
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                        <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                            <Dropdown overlay={saleTemplatesOptions}>
                                                <Link to="#" data-toggle="dropdown" title="Download">
                                                    <FilePdfOutlined />
                                                </Link>
                                            </Dropdown>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Send sale')}>
                                            <Button type="default" onClick={() => this.sentEmailTemplate('sale')} ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                                <SendOutlined />
                                            </Button>
                                    </Tooltip> </>
                                }
                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Editable templates')}>
                                    <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                        <Dropdown overlay={editTemplatesSaleOptions}>
                                            <Link to="#" data-toggle="dropdown" title="Editable template">
                                                <SnippetsOutlined />
                                            </Link>
                                        </Dropdown>
                                    </Button>
                                </Tooltip> 
                                {showDeliveryLink &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create delivery')}>
                                        <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Link to={"/" + this.props.match.params.db + "/delivery/new?ids=" + this.props.match.params.id}>
                                                <DropboxOutlined />
                                            </Link>
                                        </Button>
                                    </Tooltip>  
                                }
                                {menuOptions && 
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create Task')}>
                                        <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Dropdown overlay={menuOptions}>
                                                <Link to="#" data-toggle="dropdown" title="New Task">
                                                    <ScheduleOutlined />
                                                </Link>
                                            </Dropdown> 
                                        </Button>
                                    </Tooltip> 
                                }
                                {this.state.checkedSaleLines && this.state.checkedSaleLines.length ?
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}>
                                                <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/item-requests/new?saleLineIds=" + this.state.checkedSaleLines.join(',') + "&location=" + sale.locId }>
                                                    <i className="fa fa-truck" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}></i>
                                                    </Link> 
                                                </Button>
                                        </Tooltip> 
                                        : ''
                                    }
                            </div>
                        }
                        {this.state.profileTab === 'invoice' && window.innerWidth < 780 &&
                            <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                                    {roles.invoices && roles.invoices.hasEdit===true &&
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <Link to={"/" + this.props.match.params.db + "/invoice/"+invoice.secureId+'/edit'}>
                                                    <EditOutlined />
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    }
                                    {this.state.invoiceTemplate &&  
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <Dropdown overlay={invoiceTemplatesOptions}>
                                                    <Link to="#" data-toggle="dropdown" title="Download">
                                                         <FilePdfOutlined /> 
                                                    </Link>
                                                </Dropdown>
                                            </Button>
                                        </Tooltip>  
                                    }
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Editable templates')}>
                                        <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Dropdown overlay={editTemplatesInvoiceOptions}>
                                                <Link to="#" data-toggle="dropdown" title="Editable templat">
                                                    <SnippetsOutlined />
                                                </Link>
                                            </Dropdown>
                                        </Button>
                                    </Tooltip> 
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Default template')}>
                                        <Dropdown overlay={defaultInvoiceTemplateOptions}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <FileDoneOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip> 
                                    {menuOptions &&
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create Task')}>
                                        <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Dropdown overlay={menuOptions}>
                                                <Link to="#" data-toggle="dropdown" title="New Task">
                                                    <ScheduleOutlined />
                                                </Link>
                                            </Dropdown> 
                                        </Button>
                                    </Tooltip> 
                                    }
                                    {this.state.checkedSaleLines && this.state.checkedSaleLines.length ?
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}>
                                                <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/item-requests/new?saleLineIds=" + this.state.checkedSaleLines.join(',') + "&location=" + sale.locId }>
                                                    <i className="fa fa-truck" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}></i>
                                                    </Link> 
                                                </Button>
                                        </Tooltip> 
                                        : ''
                                    }
                                </div>
                        }
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                            <GeneralInformation
                                roles={roles.crm}
                                document={sale}
                                stages={stages}
                                currency={currencySign}
                                update={this.update}
                                asyncUrl={'/' + this.props.settings.db + '/api/users'}
                                changeStageUrl={'/' + this.props.match.params.db + '/api/sales/'}
                                users={users}
                                linkedDocuments={linkedDocuments}
                                paymentStagesOptions={paymentStagesOptions}
                                inventoryStagesOptions={inventoryStagesOptions}
                                itemRequestStagesOptions={itemRequestStagesOptions}
                                invoice={invoice}
                                inventories={inventories}
                                type="sale"

                            />
                            <div className="account-profile-other-information">
                                <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter" />
                                {sherableLink &&
                                    <SharesInfo sale={sale} sherableLink={sherableLink} shares={shares} update={this.update} users={users}/>
                                }
                                <PaymentsInfo roles={roles.payments} sale={sale} payments={payments} paymentMethods={paymentMethods} paymentTypes={paymentTypes} isSale={true} module='sales'/>
                                <ReversedDocs roles={roles.sales} reversedDocs={reversedSales} title={'Reversed Sales'} type={'sale'} />
                                <div className="separate"></div>
                                <InventoryInfo inventories={inventories} />
                                {invoice && <InvoiceSalesInfo invoiceSales={invoiceSales} invoice={invoice}/>}
                                <LoggedChanges data={changes} title={'Logged changes'} />
                            </div>
                        </div>
                    </div>
                     <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        {this.state.profileTab === 'sale' &&
                            <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                                {window.innerWidth > 780 &&
                                    <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                                        {roles.sales && roles.sales.hasEdit === true &&
                                            <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                                <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/sales/" + this.props.match.params.id + '/edit'}>
                                                         <EditOutlined />
                                                    </Link>
                                                </Button>
                                            </Tooltip>
                                        }
                                        {roles.sales && roles.sales.hasEdit===true &&
                                            <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit-beta')}>
                                                <Button type="default" ghost style={{color: 'green', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/sale-new/"+this.props.match.params.id+'/edit'}>
                                                        <FormOutlined />
                                                    </Link>
                                                </Button>
                                            </Tooltip>
                                        }
                                        {this.state.saleTemplate &&
                                            <>
                                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                                    <Button type="default" ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                                        <a href={'/' + this.props.match.params.db + '/sales/' + this.props.match.params.id + '/template/' + this.state.saleTemplate + '/export.pdf'} target="_blank">
                                                            <FilePdfOutlined />
                                                        </a>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Send sale')}>
                                                        <Button type="default"  onClick={() => this.sentEmailTemplate('sale')} ghost style={{ color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px' }}>
                                                            <SendOutlined />
                                                        </Button>
                                                </Tooltip>
                                            </>
                                        }
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Editable templates')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <Dropdown overlay={editTemplatesSaleOptions}>
                                                    <Link to="#" data-toggle="dropdown" title="Editable template">
                                                        <SnippetsOutlined />
                                                    </Link>
                                                </Dropdown>
                                            </Button>
                                        </Tooltip> 
                                        {showDeliveryLink &&
                                            <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create delivery')}>
                                                <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/delivery/new?ids=" + this.props.match.params.id}>
                                                        <DropboxOutlined />
                                                    </Link>
                                                </Button>
                                            </Tooltip>  
                                        }
                                        {menuOptions &&
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create Task')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <Dropdown overlay={menuOptions}>
                                                    <Link to="#" data-toggle="dropdown" title="New Task">
                                                        <ScheduleOutlined />
                                                    </Link>
                                                </Dropdown> 
                                            </Button>
                                        </Tooltip>
                                        }
                                        {this.state.checkedSaleLines && this.state.checkedSaleLines.length ?
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}>
                                                <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/item-requests/new?saleLineIds=" + this.state.checkedSaleLines.join(',') + "&location=" + sale.locId }>
                                                    <i className="fa fa-truck" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}></i>
                                                    </Link> 
                                                </Button>
                                        </Tooltip> 
                                        : ''
                                    } 
                                    </div>
                                }
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutter sale-tabs">
                                    {window.innerWidth > 780 &&
                                        <button className={this.state.active == 'sale' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('sale')}>
                                            {plainTranslate(this.props.settings.locale, 'Sale')}
                                        </button>
                                    }
                                    <button className={this.state.active == 'saleRows' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('saleRows')}>
                                        {plainTranslate(this.props.settings.locale, 'Sales Rows')}
                                    </button>
                                    <button className={this.state.active == 'requestedItems' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('requestedItems')}>
                                        {plainTranslate(this.props.settings.locale, 'Requested items')}
                                    </button>
                                    {isAccounting && roles.ledger && roles.ledger.hasView === true &&
                                        <button className={this.state.active == 'ledgerEntry' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('ledgerEntry')}>
                                            {plainTranslate(this.props.settings.locale, 'Ledger entry')}
                                        </button>
                                    }
                                    {/* {cartSale && isOnlineSale &&
                                        <button className={this.state.active == 'onlineSale' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('onlineSale')}>
                                            {plainTranslate(this.props.settings.locale, 'Cart sale data')}
                                        </button>
                                    } */}
                                    {this.state.active === 'sale' && window.innerWidth > 780 &&
                                        <div style={{width: 250, float: 'right', paddingBottom: 10}}>
                                            <Select
                                                onChange={this.handleTemplateChange}
                                                onBlurResetsInput={false}
                                                onCloseResetsInput={false}
                                                menuPlacement={"auto"}
                                                options={saleTemplates}
                                                value={saleTemplates ? saleTemplates.find(o => o.value === this.state.saleTemplate): null}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 col-xs-12 no-gutter">
                                    {
                                        this.state.active === 'sale' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                            {this.state.saleTemplate && window.innerWidth > 780 && 
                                            <embed  
                                            src={'/' + this.props.match.params.db + '/sales/' + this.props.match.params.id + '/template/' + this.state.saleTemplate + '/export.pdf?token='+localStorage.jwtToken} type="application/pdf" height="775px" width="100%" />}
                                        </div>
                                    }
                                    {
                                        this.state.active === 'saleRows' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table 
                                            rowKey={record => record.id} 
                                            columns={salesColumns} 
                                            dataSource={saleLines} 
                                            scroll={{ x: 1500, y: 650 }} 
                                            bordered={true}
                                            style={{border: 'none'}}/>
                                        </div>
                                    }
                                    {this.state.active === 'requestedItems' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table
                                                rowKey={record => record.id}
                                                columns={itemRequestColumns}
                                                dataSource={orderedItems}
                                                expandedRowRender={expandedRow}
                                            />
                                        </div>
                                    }
                                    {isAccounting && roles.ledger && roles.ledger.hasView === true && this.state.active === 'ledgerEntry' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            {ledger && !this.state.disableButton ?
                                                <>
                                                    <NewForm
                                                        formRoute={'/' + this.props.match.params.db + '/api/new-ledger/'}
                                                        saveRoute={'/' + this.props.match.params.db + '/api/new-ledger/'}
                                                        match={{ params: { id: ledger } }}
                                                        type={this.state.formId}
                                                        isNew={true}
                                                        popup={true}
                                                        form={'initializeFromState2'}
                                                    />
                                                </>
                                                :
                                                <>
                                                    {accountingOptions && !this.state.disableButton &&
                                                        <>
                                                            <Dropdown overlay={accOptionsMenu} >
                                                                <Button style={{ margin: '30px' }}>{plainTranslate(this.props.settings.locale, 'Add ledger')}</Button>
                                                            </Dropdown>
                                                        </>
                                                    }
                                                    {!accountingOptions && !this.state.disableButton &&
                                                        <><Link style={{ fontSize: '20px', margin: '30px' }} to={'/' + this.props.match.params.db + '/form-types-v2'}>{plainTranslate(this.props.settings.locale, 'No accounting templates. Click here to add some')}</Link></>
                                                    }
                                                    {this.state.disableButton &&
                                                        <FormLoader />
                                                    }
                                                </>
                                            }
                                        </div>
                                    }
                                    {/* {
                                        this.state.active === 'onlineSale' && isOnlineSale && cartSale &&
                                        <div>
                                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                                <Row align="top" type="flex">
                                                    <Col md={6} xs={24} className="onlineOrder-status delivery">
                                                        <div className="container-status delivery-bg">
                                                            <h4 style={{ display: "inline-flex" }}><CarOutlined theme="twoTone" twoToneColor="#52c41a" style={{ marginRight: 5 }} /> {plainTranslate(this.props.settings.locale, 'Delivery method')}</h4>
                                                        </div>
                                                        <div className="container-status">
                                                            <h4><strong>{cartSale ? cartSale.cart.deliveryMethodName : ''}</strong></h4>
                                                            <h5><strong>{cartSale ? cartSale.cart.shopName : ''}</strong></h5>
                                                        </div>
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col md={6} xs={24} className="onlineOrder-status payment">
                                                        <div className="container-status payment-bg">
                                                            <h4 style={{ display: "inline-flex" }}> <WalletOutlined theme="twoTone" twoToneColor="#f39c12" style={{ marginRight: 5 }} /> {plainTranslate(this.props.settings.locale, 'Payment method')}</h4>
                                                        </div>
                                                        <div className="container-status">
                                                            <h4><strong>{cartSale ? cartSale.cart.paymentMethodName : ''}</strong></h4>
                                                        </div>
                                                    </Col>
                                                    <Col span={1} />
                                                    <Col md={10} className="onlineOrder-customerData">
                                                        {cartSale && cartSale.cart.user &&
                                                            <div className="onlineOrder-right-side">
                                                                <Card title={plainTranslate(this.props.settings.locale, 'Customer Details')} extra={cartSale.cart.account ?
                                                                    <span>
                                                                        <p><Link to={'/' + this.props.match.params.db + '/account/' + cartSale.cart.account.id + '/profile'}> <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> {plainTranslate(this.props.settings.locale, 'Go to ')} {cartSale.cart.account.firstName} {cartSale.cart.account.lastName}</Link></p>
                                                                    </span>
                                                                    :
                                                                    <span onClick={() => this.openModal('showAccountModal')}><i className="fa fa-user-circle"></i> {plainTranslate(this.props.settings.locale, 'Create account')}</span>}>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Name')}: <strong>{cartSale.cart.user.firstName} {cartSale.cart.user.lastName}</strong></p>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Email')}: <strong>{cartSale.cart.user.email}</strong></p>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Phone')}: <strong>{cartSale.cart.account ? cartSale.cart.account.phone : ''}</strong></p>
                                                                </Card>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                                
                                                {this.props.match.params.db === 'elimex' ?
                                                <Tabs defaultActiveKey="order-data">
                                                    <TabPane key="order-data" tab={
                                                        <span>
                                                            <ShopOutlined />
                                                            {plainTranslate(this.props.settings.locale, 'Order data')}
                                                        </span>
                                                    }>
                                                        <div>
                                                            <Table rowKey={record => record.id} columns={lineColumns} dataSource={cartSale ? cartSale.cart.lines : []} scroll={{ x: 1500, y: 300 }} />
                                                        </div>
                                                    </TabPane>
                                                    {cartSale && cartSale.cart.address &&
                                                        <TabPane key="shipping-address" tab={
                                                            <span>
                                                                <CarOutlined />
                                                                {plainTranslate(this.props.settings.locale, 'Shipping Address')}
                                                            </span>
                                                        }>
                                                            <div className="onlineOrder-right-side no-gutter">
                                                                <Card title={plainTranslate(this.props.settings.locale, 'Shipping Address')} extra={cartSale.cart.account && <span onClick={() => this.openModal('showAddressModal')}><FileAddOutlined /> {plainTranslate(this.props.settings.locale, 'Create address')}</span>}>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Name')}: <strong>{cartSale.cart.address.firstName} {cartSale.cart.address.lastName}</strong> </p>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {cartSale.cart.address.country}</strong></p>
                                                                    {cartSale.cart.address.city &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {cartSale.cart.address.city}</strong> </p>}
                                                                    {cartSale.cart.address.code &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Code')}: <strong> {cartSale.cart.address.code}</strong> </p>}
                                                                    {cartSale.cart.address.phone &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Phone')}: <strong> {cartSale.cart.address.phone}</strong> </p>}
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Address')}: <strong> {cartSale.cart.address.userAddress}</strong></p>
                                                                    {cartSale.cart.accountAddresses &&
                                                                        <h3>{plainTranslate(this.props.settings.locale, 'Account addreses')}</h3>
                                                                    }
                                                                    {cartSale.cart.accountAddresses && cartSale.cart.accountAddresses.map((address) =>
                                                                        <Card title={plainTranslate(this.props.settings.locale, address.name)} extra={<span onClick={() => this.setDataToSale('address', address.id, sale.id)}><CopyOutlined />  {plainTranslate(this.props.settings.locale, 'Set address to sale')}</span>}>
                                                                            {address.district &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'District')}:<strong> {address.district}</strong></p>}
                                                                            {address.city &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'City')}:<strong> {address.city}</strong></p>}
                                                                            {address.postCode &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Code')}:<strong> {address.postCode}</strong></p>}
                                                                            {address.phone &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Phone')}:<strong> {address.phone}</strong></p>}
                                                                            {address.addressLine1 &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Address line 1')}:<strong> {address.addressLine1}</strong></p>}
                                                                            {address.addressLine2 &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Address line 2')}:<strong> {address.addressLine2}</strong></p>}
                                                                        </Card>
                                                                    )
                                                                    }
                                                                </Card>
                                                            </div>
                                                        </TabPane>
                                                    }
                                                    {cartSale && cartSale.cart.billing &&
                                                        <TabPane key="billing-data" tab={
                                                            <span>
                                                                <WalletOutlined />
                                                                {plainTranslate(this.props.settings.locale, 'Billing data')}
                                                            </span>
                                                        } >
                                                            <div className="onlineOrder-right-side">
                                                              <Card title={plainTranslate(this.props.settings.locale, 'Billing data')} extra={cartSale.cart.account ? <span onClick={() => this.openModal('showBillingModal')}><FileAddOutlined /> {plainTranslate(this.props.settings.locale, 'Create billing data')}</span> : ''}>
                                                                {cartSale.cart.billing.billingName &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Name')}: <strong> {cartSale.cart.billing.billingName}</strong></p>}
                                                                {cartSale.cart.billing.address &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Address')}: <strong> {cartSale.cart.billing.address}</strong></p>}
                                                                {cartSale.cart.billing.city &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {cartSale.cart.billing.city}</strong></p>}
                                                                {cartSale.cart.billing.country &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {cartSale.cart.billing.country}</strong></p>}
                                                                {cartSale.cart.billing.postCode &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Post code')}: <strong> {cartSale.cart.billing.postCode}</strong></p>}
                                                                {cartSale.cart.billing.eik &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'VAT')}: <strong> {cartSale.cart.billing.eik}</strong></p>}
                                                                <p>{plainTranslate(this.props.settings.locale, 'VAT Registration')}: <strong> {cartSale.cart.billing.isVat ? "Yes" : "No"}</strong></p>
                                                                {cartSale.cart.billing.mol &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Responsible person')}:<strong> {cartSale.cart.billing.mol}</strong></p>}
                                                                {cartSale.cart.accountBillingDatas &&
                                                                      <h3>{plainTranslate(this.props.settings.locale, 'Account billing data')}</h3>
                                                                      }
                                                              {cartSale.cart.accountBillingDatas && cartSale.cart.accountBillingDatas.map((billing) =>
                                                                    <Card title={plainTranslate(this.props.settings.locale, billing.name)} extra={<span onClick={() => this.setDataToSale('billing', billing.id, sale.id)}><CopyOutlined />  {plainTranslate(this.props.settings.locale, 'Set billing to sale')}</span>}>
                                                                        {billing.vat &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'VAT')}: <strong> {billing.vat}</strong></p>}
                                                                        {billing.uic &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'UIC')}: <strong> {billing.uic}</strong></p>}
                                                                        {billing.responsiblePerson &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Responsible person')}: <strong> {billing.responsiblePerson}</strong></p>}
                                                                        {billing.address1 &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Address line 1')}: <strong> {billing.address1}</strong></p>}
                                                                        {billing.address2 &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Address line 2')}: <strong> {billing.address2}</strong></p>}
                                                                        {billing.city &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {billing.city}</strong></p>}
                                                                        {billing.country &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {billing.country}</strong></p>}
                                                                         {billing.postCode &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Post code')}: <strong> {billing.postCode}</strong></p>}
                                                                    </Card>
                                                                    )
                                                                    }
                                                                </Card>
                                                            </div>
                                                        </TabPane>
                                                    }
                                                </Tabs>
                                                :
                                                <Tabs defaultActiveKey="order-data">
                                                    <TabPane key="order-data" tab={
                                                        <span>
                                                            <ShopOutlined />
                                                            {plainTranslate(this.props.settings.locale, 'Order data')}
                                                        </span>
                                                    }>
                                                        <div>
                                                            <Table rowKey={record => record.id} columns={lineColumns} dataSource={cartSale ? cartSale.cart.lines : []} scroll={{ x: 1500, y: 300 }} />
                                                        </div>
                                                    </TabPane>
                                                    {cartSale && cartSale.cart.address &&
                                                        <TabPane key="shipping-address" tab={
                                                            <span>
                                                                <CarOutlined />
                                                                {plainTranslate(this.props.settings.locale, 'Shipping Address')}
                                                            </span>
                                                        }>
                                                            <div className="onlineOrder-right-side no-gutter">
                                                                <Card title={plainTranslate(this.props.settings.locale, 'Shipping Address')} extra={cartSale.cart.account && <span onClick={() => this.openModal('showAddressModal')}><FileAddOutlined /> {plainTranslate(this.props.settings.locale, 'Create address')}</span>}>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Name')}: <strong>{cartSale.cart.address.name}</strong> </p>
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {cartSale.cart.address.country}</strong></p>
                                                                    {cartSale.cart.address.city &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {cartSale.cart.address.city}</strong> </p>}
                                                                    {cartSale.cart.address.district &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'District')}: <strong> {cartSale.cart.address.district}</strong> </p>}
                                                                    {cartSale.cart.address.postCode &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Post Code')}: <strong> {cartSale.cart.address.postCode}</strong> </p>}
                                                                    {cartSale.cart.address.contacts &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Contacts')}: <strong> {cartSale.cart.address.contacts}</strong> </p>}
                                                                    {cartSale.cart.address.representative &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Representative')}: <strong> {cartSale.cart.address.representative}</strong> </p>}
                                                                    {cartSale.cart.address.phone &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Phone')}: <strong> {cartSale.cart.address.phone}</strong> </p>}
                                                                    <p>{plainTranslate(this.props.settings.locale, 'Address')}: <strong> {cartSale.cart.address.address}</strong></p>
                                                                    {cartSale.cart.accountAddresses &&
                                                                        <h3>{plainTranslate(this.props.settings.locale, 'Account addreses')}</h3>
                                                                    }
                                                                    {cartSale.cart.accountAddresses && cartSale.cart.accountAddresses.map((address) =>
                                                                        <Card title={plainTranslate(this.props.settings.locale, address.name)} extra={<span onClick={() => this.setDataToSale('address', address.id, sale.id)}><CopyOutlined />  {plainTranslate(this.props.settings.locale, 'Set address to sale')}</span>}>
                                                                            {address.name &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Name')}:<strong> {address.name}</strong></p>}
                                                                            {address.country &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Country')}:<strong> {address.country}</strong></p>}
                                                                            {address.district &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'District')}:<strong> {address.district}</strong></p>}
                                                                            {address.city &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'City')}:<strong> {address.city}</strong></p>}
                                                                            {address.postCode &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Code')}:<strong> {address.postCode}</strong></p>}
                                                                            {address.phone &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Phone')}:<strong> {address.phone}</strong></p>}
                                                                            {address.email &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Email')}:<strong> {address.email}</strong></p>}
                                                                            {address.addressLine1 &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Address')}:<strong> {address.addressLine1}</strong></p>}
                                                                            {address.addressLine2 &&
                                                                                <p>{plainTranslate(this.props.settings.locale, 'Address')}:<strong> {address.addressLine2}</strong></p>}
                                                                        </Card>
                                                                    )
                                                                    }
                                                                </Card>
                                                            </div>
                                                        </TabPane>
                                                    }
                                                    {cartSale && cartSale.cart.billing &&
                                                        <TabPane key="billing-data" tab={
                                                            <span>
                                                                <WalletOutlined />
                                                                {plainTranslate(this.props.settings.locale, 'Billing data')}
                                                            </span>
                                                        } >
                                                            <div className="onlineOrder-right-side">
                                                              <Card title={plainTranslate(this.props.settings.locale, 'Billing data')} extra={cartSale.cart.account ? <span onClick={() => this.openModal('showBillingModal')}><FileAddOutlined /> {plainTranslate(this.props.settings.locale, 'Create billing data')}</span> : ''}>
                                                                {cartSale.cart.billing.name &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Name')}: <strong> {cartSale.cart.billing.name}</strong></p>}
                                                                {cartSale.cart.billing.address &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Address')}: <strong> {cartSale.cart.billing.address}</strong></p>}
                                                                {cartSale.cart.billing.city &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {cartSale.cart.billing.city}</strong></p>}
                                                                {cartSale.cart.billing.district &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'District')}: <strong> {cartSale.cart.billing.district}</strong></p>}
                                                                {cartSale.cart.billing.country &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {cartSale.cart.billing.country}</strong></p>}
                                                                {cartSale.cart.billing.postCode &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Post code')}: <strong> {cartSale.cart.billing.postCode}</strong></p>}
                                                                {cartSale.cart.billing.nationalId &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'National Id')}: <strong> {cartSale.cart.billing.nationalId}</strong></p>}
                                                                {cartSale.cart.billing.taxId &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Tax Id')}: <strong> {cartSale.cart.billing.taxId}</strong></p>}
                                                                
                                                                {cartSale.cart.billing.representative &&
                                                                <p>{plainTranslate(this.props.settings.locale, 'Representative')}:<strong> {cartSale.cart.billing.representative}</strong></p>}
                                                                {cartSale.cart.accountBillingDatas &&
                                                                      <h3>{plainTranslate(this.props.settings.locale, 'Account billing data')}</h3>
                                                                      }
                                                              {cartSale.cart.accountBillingDatas && cartSale.cart.accountBillingDatas.map((billing) =>
                                                                    <Card title={plainTranslate(this.props.settings.locale, billing.name)} extra={<span onClick={() => this.setDataToSale('billing', billing.id, sale.id)}><CopyOutlined />  {plainTranslate(this.props.settings.locale, 'Set billing to sale')}</span>}>
                                                                        {billing.uic &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'VAT')}: <strong> {billing.uic}</strong></p>}
                                                                        {billing.vat &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'UIC')}: <strong> {billing.vat}</strong></p>}
                                                                        {billing.responsiblePerson &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Responsible person')}: <strong> {billing.responsiblePerson}</strong></p>}
                                                                        {billing.fullAddress &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Full Address')}: <strong> {billing.fullAddress}</strong></p>} 
                                                                        {billing.address1 &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Address line 1')}: <strong> {billing.address1}</strong></p>}  
                                                                        {billing.address2 &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Address line 1')}: <strong> {billing.address2}</strong></p>}  
                                                                        {billing.city &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'City')}: <strong> {billing.city}</strong></p>}
                                                                        {billing.country &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Country')}: <strong> {billing.country}</strong></p>}
                                                                         {billing.postCode &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Post code')}: <strong> {billing.postCode}</strong></p>}
                                                                        {billing.label &&
                                                                        <p>{plainTranslate(this.props.settings.locale, 'Label')}: <strong> {billing.label}</strong></p>}
                                                                    </Card>
                                                                    )
                                                                    }
                                                                </Card>
                                                            </div>
                                                        </TabPane>
                                                    }
                                                </Tabs> 
                                                }
                                            </div>
                                        </div>
                                    } */}
                                </div>
                            </div>
                        }
                        {this.state.profileTab === 'invoice' &&
                            <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                                {window.innerWidth > 780 && <div className="col-md-12 document-profile-tabs" style={{ margin: "10px 0 30px 0" }}>
                                    {roles.invoices && roles.invoices.hasEdit===true &&
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Edit')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <Link to={"/" + this.props.match.params.db + "/invoice/"+invoice.secureId+'/edit'}>
                                                    <EditOutlined />
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    }
                                    {this.state.invoiceTemplate &&  
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Download')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <a href={"/" + this.props.match.params.db + "/invoicing/invoice/"+invoice.secureId+'/template/'+this.state.invoiceTemplate+'/export.pdf'} target="_blank">
                                                    <FilePdfOutlined /> 
                                                </a>
                                            </Button>
                                        </Tooltip>  
                                    }
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Editable templates')}>
                                        <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                            <Dropdown overlay={editTemplatesInvoiceOptions}>
                                                <Link to="#" data-toggle="dropdown" title="Editable templat">
                                                    <SnippetsOutlined />
                                                </Link>
                                            </Dropdown>
                                        </Button>
                                    </Tooltip> 
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Default template')}>
                                        <Dropdown overlay={defaultInvoiceTemplateOptions}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <FileDoneOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip> 
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Send invoice')}>
                                            <Button type="default" onClick={() => this.sentEmailTemplate('invoice')} ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <SendOutlined />
                                            </Button>
                                    </Tooltip> 
                                    <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Copy public invoice link')}>
                                        <Dropdown overlay={publicInvoiceOptions}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <LinkOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                    {menuOptions && 
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create Task')}>
                                            <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                <Dropdown overlay={menuOptions}>
                                                    <Link to="#" data-toggle="dropdown" title="New Task">
                                                        <ScheduleOutlined />
                                                    </Link>
                                                </Dropdown> 
                                            </Button>
                                        </Tooltip> 
                                    }
                                    {this.state.checkedSaleLines && this.state.checkedSaleLines.length ?
                                        <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}>
                                                <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                                                    <Link to={"/" + this.props.match.params.db + "/item-requests/new?saleLineIds=" + this.state.checkedSaleLines.join(',') + "&location=" + sale.locId }>
                                                    <i className="fa fa-truck" style={{ cursor: 'pointer' }} title={plainTranslate(this.props.settings.locale, 'Create multiple item requests')}></i>
                                                    </Link> 
                                                </Button>
                                        </Tooltip> 
                                        : ''
                                    }
                                </div>}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-gutter">
                                    <div style={{width: 250, float: 'right', paddingBottom: 10}}>
                                        {window.innerWidth > 780 && 
                                            <Select
                                                onChange={this.handleTemplateChange}
                                                onBlurResetsInput={false}
                                                onCloseResetsInput={false}
                                                menuPlacement={"auto"}
                                                options={invoiceTemplates}
                                                value={invoiceTemplates ? invoiceTemplates.find(o => o.value === this.state.invoiceTemplate) : null}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                    <div className="col-md-12 col-xs-12 no-gutter">
                                        {this.state.invoiceTemplate && window.innerWidth > 780 && <embed src={ '/' + this.props.match.params.db + '/invoicing/invoice/' + invoice.secureId + '/template/' + this.state.invoiceTemplate + '/export.pdf'} type="application/pdf" height="775px" width="100%" />}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.newTaskPopup &&
                    <SlideModal onClose={() => this.setState({ newTaskPopup: false })} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel" ref={this.setWrapperRef}>
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <NewForm
                                        formRoute={'/' + this.props.match.params.db + '/api/tasks/form'}
                                        saveRoute={'/' + this.props.match.params.db + '/api/tasks?accountId=' + sale.accountId}
                                        match={{ params: { id: 0 } }}
                                        type={this.state.formId}
                                        refs={sale.accId}
                                        sale={sale.id}
                                        onCancel={() => this.setState({ newTaskPopup: false })}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState2'}
                                        onSuccessfulSave={() => this.setState({ newTaskPopup: false })}
                                    /*account={this.props.accountId}
                                    type={this.state.taskForm}
                                    refs={this.state.taskProject ? this.state.taskProject : null}*/
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                { this.state.confirmPopup &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Create production')}
                        onClose={() => this.setState({ confirmPopup: false, record: false })}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">
                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure you want to create production?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ confirmPopup: false, record: false })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-outlines text-center btn-ok"
                                            onClick={() => this.createManufacturing(this.state.record)}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
                {/* {this.state.showAccountModal &&
                    <SlideModal onClose={() => this.setState({ showAccountModal: false })} title={plainTranslate(this.props.settings.locale, 'Account')}>
                        <section className="newPanel" ref={this.setWrapperRef}>
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/account/form/'}
                                        saveRoute={this.props.match.params.db + '/api/accounts/new?id=' + listId}
                                        popup={true}
                                        updateParent={() => this.updateData('showAccountModal')}
                                        onCancel={() => this.setState({ showAccountModal: false })}
                                        userId={cartSale.cart.user.id}
                                        match={{ params: { id: listId } }}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                } */}
                {/* {
                    this.state.showAddressModal &&
                    <SlideModal onClose={() => this.setState({ showAddressModal: false })} title={plainTranslate(this.props.settings.locale, 'Address')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/address/'}
                                        saveRoute={this.props.match.params.db + '/api/address?accountId=' + cartSale.cart.account.id}
                                        updateParent={() => this.updateData('showAddressModal')}
                                        popup={true}
                                        isNew={true}
                                        onCancel={() => this.setState({ showAddressModal: false })}
                                        match={{ params: { id: 0 } }}
                                        cartSaleId={cartSale.id}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                } */}
                {/* {
                    this.state.showBillingModal &&
                    <SlideModal onClose={() => this.setState({ showBillingModal: false })} title={plainTranslate(this.props.settings.locale, 'Billing data')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/billing-data/'}
                                        saveRoute={this.props.match.params.db + '/api/billing-data'}
                                        updateParent={() => this.updateData('showBillingModal')}
                                        popup={true}
                                        isNew={true}
                                        onCancel={() => this.setState({ showBillingModal: false })}
                                        match={{ params: { id: 0 } }}
                                        refs={cartSale.cart.account.id}
                                        cartSaleId={cartSale.id}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                } */}
                <Drawer className="grid-popup-form" title="Basic Drawer" placement="right" visible={this.state.showEditTemplate} width={'90%'} onClose={() => this.setState({ showEditTemplate : false})}>
                    <EditablePrint {...this.props} type={this.state.profileTab} db={this.props.match.params.db} templateId={this.state.templateId} docId={this.state.docId}/>
                </Drawer>
                {this.state.emailPopup === true &&
                    <SlideModal onClose={() => this.setState({emailPopup: false})} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                    <section className="newPanel">
                      <div className="panel-body">
                        <div className="row m-bot15">
                            <Form
                                formRoute={this.props.match.params.db + '/api/message-email/'}
                                saveRoute={this.props.match.params.db + '/api/message-email/new'}
                                popup={true}
                                onSuccessfulSave={() => this.setState({ emailPopup: false })}
                                onCancel={() => this.setState({ emailPopup: false })}
                                refs={sale.accountEmail ? sale.accountEmail : ' '}
                                type={this.state.typeEmail == "invoice" ? JSON.stringify({"invoiceCc": invoice.cc, "templateCc": this.state.invoiceTemplateCc, "type": this.state.typeEmail}) : JSON.stringify({"saleId": sale.id, "type": this.state.typeEmail})}
                                form={'initializeFromState'}
                                match={{ params: { id: 0 } }}
                                isNew={true}
                                sendButton={true}
                            />
                        </div>
                      </div>
                    </section>
                  </SlideModal>
                }
                { this.state.accountingTemplate &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Create ledger')}
                        onClose={() => this.setState({ accountingTemplate: false })}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">
                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure you want to create accounting ledger?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ accountingTemplate: false })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-outlines text-center btn-ok"
                                            onClick={() => this.createAccounting(sale.id)}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    salesProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
    onEditOptionsLoad: (route) => dispatch(onEditOptionsLoad(route)),
    onTabsLoad: (route) => dispatch(onTabsLoad(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesProfile);
