import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment'
import { Menu, Avatar, Badge, Input, Select, AutoComplete, Spin, Row, Col } from 'antd';
import { CaretDownOutlined, PrinterOutlined, UserOutlined, ScheduleOutlined, HddOutlined } from '@ant-design/icons'
import { Link, withRouter} from 'react-router-dom';
import Translate, {plainTranslate} from '../common/translate';
import { sendNotification, getCurrentNotifications, changeStatusNotification } from '../../actions/notificationActions'
import '../../css/layout.css';
import '../../css/settings.css';
import Popup from '../common/popup';
import { CalendarOutlined, CalendarTwoTone, BulbOutlined } from '@ant-design/icons'
import 'antd/lib/select/style/index.css';
import 'antd/lib/input/style/index.css';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import { GlobalHotKeys } from "react-hotkeys";
import NewMenuContent from "./newContent";
import "./header.css";
import client from '../../actions/client'
import {logoutUser} from '../../actions/authActions';
import Fingerprint2 from 'fingerprintjs2';
import ClientJS from "clientjs";

const { Option } = Select;
const InputGroup = Input.Group;
const { SubMenu } = Menu;

const clientJS = new ClientJS();

const keyMap = {
    showShortcuts: ['ctrl+m'],
    findAccount: ['ctrl+up'],
    showNewMenu: ['ctrl+down']
};

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.firstOption = 'Accounts';
         this.state = {
            globalSearch: false,
            data: [],
            value: [],
            fetching: false,
            notification: {},
            currentNotifications: [],
            showNotificationMenu: false,
            open: false,
            showShortcuts: false,
            showNewMenu: false,
        }
        this.search = this.search.bind(this);
        this.globalSearchOption = this.globalSearchOption.bind(this);
        this.showNotificationMenu = this.showNotificationMenu.bind(this);
        this.showShortcuts = this.showShortcuts.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.clearAllNotifications = this.clearAllNotifications.bind(this);
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.showNewMenu = this.showNewMenu.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.checkDevices = this.checkDevices.bind(this);
    }

    getFingerprint1(){
      var options1 = {
          excludes: {
              webdriver: true,
              deviceMemory: true,
              openDatabase: true,
              doNotTrack: true,
              plugins: true,
              fonts: true,
              enumerateDevices: true,
              userAgent: true,
              audio: true,
              canvas: true,
              webgl: true,
              plugins: true,
              localStorage: true,
              adBlock: true,
              screenResolution: true,
              availableScreenResolution: true,
              enumerateDevices: true,
              pixelRatio: true,
              doNotTrack: true
          }
      }

          var options2 = {
            excludes: {
                webdriver: true,
                deviceMemory: true,
                openDatabase: true,
                doNotTrack: true,
                plugins: true,
                fonts: true,
                enumerateDevices: true,
                audio: true,
                canvas: true,
                webgl: true
            }
        }

        var options3 = {};

        return Fingerprint2.getPromise(options1).then(function (components) {
          var values = components.map(function (component) {
              return component.value;
          })
          let fingerprints = Fingerprint2.getPromise(options2).then(function (components) {
            var values = components.map(function (component) {
                if(component.key == "userAgent"){
                    return clientJS.getBrowser();
                }else{
                    return component.value;
                }
            })
            let res = Fingerprint2.getPromise(options3).then(function (components) {

              var values = components.map(function (component) {
                  return component.value;
              })
              return Fingerprint2.x64hash128(values.join(''), 31);
            }).then(response => {
              let arr = [];
              arr.push(response);
              arr.push(Fingerprint2.x64hash128(values.join(''), 31));
              return arr;
            });
           return res;
          }).then(response =>{
             response.push(Fingerprint2.x64hash128(values.join(''), 31));
             return response;
          });
          return fingerprints;
        });
    }

    checkDevices(){
      this.getFingerprint1().then(fp => {

        var OS = clientJS.getOS();
        var browser = clientJS.getBrowser();
        var device = clientJS.getDevice();

        client.post('/' + this.props.db + '/api/devices-search', {
          db: this.props.db,
          fp1: fp[2],
          fp2: fp[1],
          fp3: fp[0],
          device: device['type'] ? true : false,
          deviceName: OS['name'] + ' ' + browser['name']
        })
        .then(response => response.data)
        .then(response => {
            localStorage.setItem('workplace', response['workplaceId']);
            if(response.url !== undefined){
              var printerStatus = document.getElementById('printer-status-new');
              client.get(response.url+'/status')
              .then(response => response.data)
              .then(response => {
                  if(response.ok === true){
                      printerStatus.style.color = "#AEEA00";
                  }
              })
              .catch(err => {
                printerStatus.style.color = "#CD201F";
              })  
          }else{
              var printerStatus = document.getElementById('printer-status-new');

              if(printerStatus){
                printerStatus.style.color = "#CD201F";
              }
          }

        })
      });
    }

  
    componentDidMount() {
      this.setState({globalSearchOption: localStorage.getItem('globalSearch') ? localStorage.getItem('globalSearch') : null});
//moved from componentWillMount
      this.props.getCurrentNotifications('/' + this.props.db + '/api/activity/notifications/all');

      if(this.props.settings.checkDevices){
        this.checkDevices();
      }

      this.interval = setInterval(() => {
       this.props.getCurrentNotifications('/' + this.props.db + '/api/activity/notifications/all');
      }, 300000);

      if(this.props.settings.checkDevices){
        this.interval2 = setInterval(() => {
          this.checkDevices();
        }, 180000);
      }
   }

  componentDidUpdate(prevProps, prevState){

    if(prevProps.settings.checkDevices !== this.props.settings.checkDevices){
        if(this.props.settings.checkDevices){
          this.checkDevices();
        }

/*        this.interval = setInterval(() => {
         this.props.getCurrentNotifications('/' + this.props.db + '/api/activity/notifications/all');
        }, 900000);*/

        if(this.props.settings.checkDevices){
          this.interval2 = setInterval(() => {
            this.checkDevices();
          }, 180000);
        }
      }
   }

   static getDerivedStateFromProps(nextProps, prevState) {        
     if(nextProps.notification && nextProps.notification.currentNotifications && nextProps.notification.currentNotifications !== prevState.currentNotifications){
       return {
            currentNotifications: nextProps.notification.currentNotifications
        }
     }else{
       return {};
     }
   }
    search() {
        this.setState({globalSearch: true});
    }

    showNotificationMenu() {
        this.setState({showNotificationMenu: !this.state.showNotificationMenu});
    }

    globalSearchOption(value) {
        this.setState({globalSearchOption: value, data: []}, function() {
            localStorage.setItem('globalSearch', value);
            this.firstOption = null;
        });
    }

    openNotificationForm(notificationObj) {
       this.setState({
           open: true,
           notification: notificationObj.id
       })
    }

    closeNotificationForm() {
       this.setState({
            open: false,
            notification: {}
        })
    }

    updateNotifications() {
        this.closeNotificationForm();
        this.props.getCurrentNotifications('/' + this.props.db + '/api/activity/notifications/all');
    }

    showShortcuts() {
          this.setState({showShortcuts: true});
    }

    showNewMenu() {
        this.setState({showNewMenu:true});
    }

    findAccount() {
          this.setState({globalSearch: true});
    }

    changeStatus(notificationObj) {
      client.get('/' + this.props.db + '/api/activity/notification/' + notificationObj.id + '/change-status')
      .then(response => response.data)
      .then(response => {
          this.props.getCurrentNotifications('/' + this.props.db + '/api/activity/notifications/all');
      })
      .catch(err => {
          console.log('error')
      });
    }

    clearAllNotifications() {
      client.get('/' + this.props.db + '/api/activity/notifications/clear')
      .then(response => response.data)
      .then(response => {
          this.props.getCurrentNotifications('/' + this.props.db + '/api/activity/notifications/all');
      })
      .catch(err => {
              console.log('error')
          });
    }

    handleEnterClick(e) {
      if (e.key === 'Enter') {
            console.log('press enter');
      }
    }

    setWrapperRef(node) {
      this.wrapperRef = node;
    }

    logoutUser = (db) => { 
      this.props.logoutUser(db)
    }

   render() {
     const handlers = {
            showShortcuts: this.showShortcuts.bind(this),
            findAccount: this.findAccount.bind(this),
            showNewMenu: this.showNewMenu.bind(this)
        };
     const settings = this.props.settings;
     const { fetching, data, value } = this.state;

    let headerTheme = {};

    if (this.props.settings.firstColor) {

        headerTheme = {
            background:"-webkit-linear-gradient(left,"+this.props.settings.firstColor+","+this.props.settings.secondColor+")"
        };
    }
     const fetchData = value => {
            this.lastFetchId += 1;
            const fetchId = this.lastFetchId;
            this.setState({ data: [], fetching: true });

            let url = '/' +this.props.db + '/api/billing-data-accounts-global-search?q=' + value;
            let profile = 'account';
            let tab = null;
            if (this.firstOption === 'Items' || this.state.globalSearchOption === 'Items') {
                url =  '/' +this.props.db + '/api/items?q=' + value +'&pageSize=50';
                profile = 'item';
            }
            if (this.firstOption === 'Sales' || this.state.globalSearchOption === 'Sales') {
                url =  '/' +this.props.db + '/api/global-search-sales?q=' + value +'&limit=50';
                profile = 'sale';
            }
            if (this.firstOption === 'Invoices' || this.state.globalSearchOption === 'Invoices') {
                url =  '/' +this.props.db + '/api/global-search-invoices?q=' + value +'&limit=50';
                profile = 'sale';
                tab = 'invoice';
            }
            if (this.firstOption === 'Expenses' || this.state.globalSearchOption === 'Expenses') {
                url =  '/' +this.props.db + '/api/global-search-expenses?q=' + value +'&limit=50';
                profile = 'expense';
            }
            if (this.firstOption === 'Projects' || this.state.globalSearchOption === 'Projects') {
                url =  '/' +this.props.db + '/api/projects?q=' + value +'&limit=50';
                profile = 'project';
            }
            if (this.firstOption === 'Tasks' || this.state.globalSearchOption === 'Tasks') {
                url =  '/' +this.props.db + '/api/tasks?q=' + value +'&limit=50';
                profile = 'task';
            }

            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(()=>{
                client.get(url)
                .then(response => response.data)
                .then(response => {
                    if (fetchId !== this.lastFetchId) {
                      // for fetch callback order
                      return;
                    }

                    let data = response.map(item => ({
                            label: item.label,
                            value: item.value,
                            link: tab ? '/' +this.props.db + '/' + profile + '/' + item.value + '/profile?tab='+tab : '/' +this.props.db + '/' + profile + '/' + item.value + '/profile'
                          }));
                    this.setState({ data, fetching: false });
                })
                .catch(err => {
                        console.log('error')
                    });

                 }, 1000);
        };

      const posMenu = (
            settings.pos && settings.pos.locations.map((location,key) => {
              if(settings.pos.forms[0]){
                return <Menu.Item key={key}>
                  <a className="ant-menu-submenu-link" href={'/' +this.props.db + '/pos/transactions/new?locId=' + location.value + "&type="+settings.pos.forms[0].value}>{location.label}</a>
                </Menu.Item>
              }else{
                 return <Menu.Item key={key}>
                  <a className="ant-menu-submenu-link" href={'/' +this.props.db + '/pos/transactions/new?locId=' + location.value}>{location.label}</a>
                </Menu.Item>
              }
            })
      );

    const posMenuWithForms = (
        settings.pos && settings.pos.locations.map((location,key) => {
          return <SubMenu
            key={location.value}
            title={location.label}
          >
          {
          settings.pos && settings.pos.forms.map((form,key1) => {
            return <Menu.Item className="ant-menu-submenu-link" key={location.value + form.value}><a href={'/' +this.props.db + '/pos/transactions/new?locId=' + location.value + "&type="+form.value}>{form.label}</a></Menu.Item>
          })}
          </SubMenu>
        })
    );

     const handleChange = value => {
            this.props.history.push(value[0].label.props.link);
            this.setState({
              data: [],
              fetching: false,
            }, function() {
                this.setState({globalSearch: false});
            });

          };

          let firstOption = null;

          if(!this.state.globalSearchOption){
            if(settings.bundles && settings.bundles.apicrmbundle &&  this.props.settings.lists && this.props.settings.lists.length > 0){
              firstOption = "Accounts";
            }
            else if(settings.administration && settings.administration.includes('items')){
              firstOption = "Items";
            }
            else if(settings.bundles && settings.bundles.salesbundle && settings.plan.sales){
              firstOption = "Sales";
            }
            else if(settings.bundles && settings.bundles.invoicebundle && settings.plan.invoice){
              firstOption = "Invoices";
            }
            else if(settings.bundles && settings.bundles.expensebundle && settings.plan.expense){
              firstOption = "Expenses";
            }
            else if(settings.plan && settings.plan.project){
              firstOption = "Projects";
            }
            else if(settings.plan && settings.plan.project){
              firstOption = "Tasks";
            }
            this.firstOption = firstOption;
          }
          let isPublic = this.props.location.pathname.indexOf('/public/invoice');
          let isPublicDeal = this.props.location.pathname.indexOf('/public/deals');
          let isPublicContract = this.props.location.pathname.indexOf('/public/document');
          let isPublicSurvey = this.props.location.pathname.indexOf('/public/survey');
          if (isPublic > 0 || isPublicSurvey > 0 || isPublicContract > 0 || isPublicDeal > 0) {
            return <></>;
          }

          return (
            <div className="containment" id="header">
              <header className={"top-bar-menu header fixed-top clearfix" + (this.props.settings.sidebar === 'nav-collapse closed' ? ' collapsed' : '')} style={headerTheme}>
                  <Row type="flex" align="middle">
                      <Col xs={8} sm={8} md={4} lg={4} xl={3} className="companyLogo">
                        {settings.companyLogo !== undefined && window.innerWidth > 767 && settings.companies &&
                          <Menu mode="horizontal">
                            <SubMenu style={{padding: 0, width: "100%"}} key="SubMenu" title={<span><Link to={'/' + this.props.db + '/dashboard'}><img src={this.props.settings.companyLogo ? this.props.settings.companyLogo.url : "/images/composity-logo-white-200.png?v1.0"}></img><CaretDownOutlined /></Link></span>}>
                              <Menu.Item key="setting:1">Option 1</Menu.Item>
                              <Menu.Item key="setting:2">Option 2</Menu.Item>
                            </SubMenu>
                          </Menu>
                        }
                        {settings.companyLogo !== undefined && window.innerWidth > 767 && !settings.companies &&
                          <span><Link to={'/' + this.props.db + '/dashboard'}><img src={this.props.settings.companyLogo ? this.props.settings.companyLogo.url : "/images/composity-logo-white-200.png?v1.0"}></img></Link></span>
                        }
                       </Col>
                      <Col xs={4} sm={4} md={1} lg={1} xl={1} >
                        <a className="add-fast-button" onClick={this.showNewMenu} title={plainTranslate(settings.locale, 'New document')}>
                          <i title={plainTranslate(settings.locale, 'New document')} className="ion-android-add"></i>
                          <i title={plainTranslate(settings.locale, 'New document')} className="ion-chevron-down"></i>
                        </a>
                       </Col>
                      <Col xs={21} sm={21} md={6} lg={7} xl={7} className="globalSearch-mobile">
                           <div className="globalSearch-box">
                              <InputGroup>
                                  <div className="col-lg-5 col-md-4 col-xs-6 no-gutter">
                                    <Select notFoundContent={null} 
                                    value={this.state.globalSearchOption ? this.state.globalSearchOption : firstOption} 
                                    className="globalSearch-select" 
                                    onChange={this.globalSearchOption}>
                                      {settings.bundles && settings.bundles.apicrmbundle &&  this.props.settings.lists && this.props.settings.lists.length > 0 &&
                                        <Option value="Accounts">{plainTranslate(settings.locale, 'Accounts')}</Option>
                                      }
                                      {settings.administration && settings.administration.includes('items') &&
                                        <Option value="Items">{plainTranslate(settings.locale, 'Items')}</Option>
                                      }
                                      {settings.bundles && settings.bundles.salesbundle && settings.plan.sales &&
                                        <Option value="Sales">{plainTranslate(settings.locale, 'Sales')}</Option>
                                      }
                                      {
                                        settings.bundles && settings.bundles.invoicebundle && settings.plan.invoice &&
                                          <Option value="Invoices">{plainTranslate(settings.locale, 'Invoices')}</Option>
                                      }
                                      {
                                        settings.bundles && settings.bundles.expensebundle && settings.plan.expense &&
                                        <Option value="Expenses">{plainTranslate(settings.locale, 'Expenses')}</Option>
                                      }
                                      {
                                        settings.plan && settings.plan.project &&
                                        <Option value="Projects">{plainTranslate(settings.locale, 'Projects')}</Option>
                                      }
                                      {
                                        settings.plan && settings.plan.project &&
                                        <Option value="Tasks">{plainTranslate(settings.locale, 'Tasks')}</Option>
                                      }
                                    </Select>
                                  </div>
                                <div className="col-lg-7 col-md-8 col-xs-6 no-gutter">
                                  <Select
                                      mode="multiple"
                                      labelInValue
                                      value={value}
                                      placeholder={<span><i id="magnifier" className="ion-ios-search first" title="Търсене"></i> {plainTranslate(settings.locale, 'Type to search')}</span>}
                                      filterOption={false}
                                      notFoundContent={fetching ? <Spin size="small" /> : null}
                                      onSearch={fetchData}
                                      onChange={handleChange}
                                      className="globalSearch-select"
                                      onDropdownVisibleChange={(e) => this.setState({data: []})}
                                    >
                                      {data.map(d => (
                                        <Option key={d.value}>
                                              <span
                                                  className="globalSearch-link"
                                                  title={d.label}
                                                  link={d.link}>
                                                  <span>{d.label}</span>
                                              </span>
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                              </InputGroup>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={13}>
                       
                        <Menu mode="horizontal">{/*"top-nav hr-top-nav"*/}
                        {settings.paid !== undefined && !settings.paid &&
                              <Menu.Item key="plan" className="Starter-plan">
                                <div className="icon upgrade" style={{paddingRight: 40}}>
                                    <p className="upgrade-plan">{plainTranslate(settings.locale, 'Starter Plan: Free')}</p>
                                    <Link to={'/' + this.props.db + '/subscription-details'} style={{color: "#fff"}}
                                    title={plainTranslate(settings.locale, 'Upgrade')} className="upgrade-link">{plainTranslate(settings.locale, 'Upgrade')}</Link>
                                </div>
                              </Menu.Item>
                            }
                        {settings.checkDevices && window.innerWidth > 767 &&
                              <Menu.Item key="fiscal-devices" >
                                <Link id="printer-link-new"
                                   to={'/' + this.props.db + '/administration/devices'}
                                   title={plainTranslate(settings.locale, 'fiscal-devices')}>
                                   <span><i id="printer-status-new" className="fa fa-print"></i> 
                                   <span className="mobileLink">&nbsp;{plainTranslate(settings.locale, 'fiscal-devices')}</span>
                                   </span>
                                </Link>
                              </Menu.Item>
                            }
                            {settings.bundles && settings.bundles.posbundle && settings.plan.pos &&
                              <SubMenu
                              style={{marginTop: '-3px'}}
                                key="sub2"
                                className="headerOption"
                                title={<span style={{color: "white", fontSize: 20}}>
                                  <i className="fa fa-shopping-basket"></i></span>}>
                              {settings.pos && settings.pos.forms.length > 1 ? posMenuWithForms : posMenu}
                              </SubMenu>
                            }
                           {settings.booking &&  <Menu.Item key="booking" style={{marginTop: 0}}>
                                <Link id="Booking"
                                   to={'/' +this.props.db + '/booking/calendar'}
                                   title={plainTranslate(settings.locale, 'Booking Calendar')}>
                                   <span>
                                   <CalendarOutlined style={{ fontSize: '20px', color: '#fff' }}/>
                                   <span className="mobileLink">&nbsp;{plainTranslate(settings.locale, 'Booking Calendar')}</span>
                                   </span>
                                </Link>
                            </Menu.Item>}
                              
                            <Menu.Item key="notification" title={plainTranslate(settings.locale, 'Notification')} onClick={this.showNotificationMenu} className="notification-container">
                              <i className="ion-android-notifications-none"></i>
                              <span class="notification-counter">{this.props.notification.countNotifications}</span>
                              <span className="mobileLink">&nbsp; {plainTranslate(settings.locale, 'Notification')}</span>
                            </Menu.Item>
                            <Menu.Item key="bookmarks">
                                <Link id="Bookmarks"
                                   to={'/' +this.props.db + '/list/bookmarks'}
                                   title={plainTranslate(settings.locale, 'Bookmarks')}>
                                   <span><i className="ion-link"></i><span className="mobileLink">&nbsp; {plainTranslate(settings.locale, 'Bookmarks')}</span></span>
                                </Link>
                            </Menu.Item>
                      <SubMenu popupClassName="submenu-header-ui" key="help" 
                            title={<i className="ion-ios-help-outline" style={{color: "white"}}></i>}>
                              <Menu.ItemGroup title={plainTranslate(settings.locale, 'Articles and Videos')}>
                                <Menu.Item key="help1">
                                  <a className="header-menu" href="https://youtu.be/jc6uJtu9AoU" target="_blank">{plainTranslate(settings.locale, 'Video')} <i className="ion-forward header-menu"></i></a>
                                </Menu.Item>
                                <Menu.Item key="help2">
                                  <a className="header-menu" href="https://composity.bg/customer-feedback" target="_blank">{plainTranslate(settings.locale, 'Feedback')} <i className="ion-forward header-menu"></i></a>
                                </Menu.Item>
                                <Menu.Item key="help3">
                                  <a className="header-menu" href='https://composity.bg/request-demo'>{plainTranslate(settings.locale, 'Request a demo')} <i className="ion-forward header-menu"></i></a>
                                </Menu.Item>
                                <Menu.Item key="help4" onClick={this.showShortcuts}>
                                  <i className="fa fa-keyboard-o header-menu"></i> {plainTranslate(settings.locale, 'Keys shortcuts')}
                                </Menu.Item>
                              </Menu.ItemGroup>
                            </SubMenu>
                      <SubMenu key="myAccount" popupClassName="submenu-header-ui"
                            title={<span className="submenu-title-wrapper">
                                  {settings.user && settings.user.avatar &&
                                    <Avatar src={settings.user.avatar.thumbUrl } />
                                  }
                                  {settings.user && !settings.user.avatar &&
                                    <Avatar size="small" icon={<UserOutlined style={{fontSize: 20}}/>}/>
                                  }
                                 <CaretDownOutlined style={{fontSize: 14, color: "#fff"}} />
                                  <span className="mobileLink">&nbsp; {plainTranslate(settings.locale, 'My account')} </span></span>
                                }
                              >
                              <Menu.ItemGroup title={plainTranslate(settings.locale, settings.user ? settings.user.username : '')}>
                                <Menu.Item key="userMenu1">
                                  <Link className="header-menu" id="account" to={'/' +this.props.db + '/user/profile'}><span className="header-menu">{plainTranslate(settings.locale, 'Account')}</span></Link>
                                </Menu.Item>
                                <Menu.Item key="userMenu2">
                                  <a onClick={() => this.logoutUser(this.props.db)} className="header-menu" href="#"><span className="header-menu">{plainTranslate(settings.locale, 'Sign out')}</span></a>
                                </Menu.Item>
                              </Menu.ItemGroup>
                              
                              <Menu.ItemGroup title={plainTranslate(settings.locale, this.props.db)}>
                              {settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                                <Menu.Item key="userMenu3">
                                  <Link className="header-menu" id="subscription" title={plainTranslate(settings.locale, ' Subscription and Billing')} to={'/' +this.props.db + '/subscription-details'}>
                                    <span className="header-menu">{plainTranslate(settings.locale, 'Subscription and Billing')}</span>
                                  </Link>
                                </Menu.Item>
                              }
                              {settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                                <Menu.Item key="userMenu4">
                                  <Link className="header-menu" id="modules" title={plainTranslate(settings.locale, 'Modules and Add-ons')} to={'/' +this.props.db + '/modules'}>
                                    <span className="header-menu">{plainTranslate(settings.locale, 'Modules and Add-ons')}</span>
                                  </Link>
                                </Menu.Item>
                              }
                              {settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                                <Menu.Item key="userMenu5">
                                  <Link className="header-menu" id="Settings" title={plainTranslate(settings.locale, 'Settings')} to={'/' +this.props.db + '/settings/general'}>
                                    <span className="header-menu">{plainTranslate(settings.locale, 'Settings')}</span>
                                  </Link>
                                </Menu.Item>
                              }
                              {settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                                <Menu.Item key="userMenu6">
                                  <Link className="header-menu" id="Statistics" title={plainTranslate(settings.locale, 'Statistics')} to={'/' +this.props.db + '/stats'}>
                                    <span className="header-menu">{plainTranslate(settings.locale, 'Statistics')}</span>
                                  </Link>
                                </Menu.Item>
                              }
                              {settings.user && settings.roles.includes("ROLE_SUPER_ADMIN") &&
                                <Menu.Item key="userMenu7">
                                  <Link className="header-menu" id="Users" title={plainTranslate(settings.locale, 'Users')} to={'/' +this.props.db + '/administration/users-v2/admin'}>
                                    <span className="header-menu">{plainTranslate(settings.locale, 'Users')}</span>
                                  </Link>
                                </Menu.Item>
                              }
                              </Menu.ItemGroup>
                              <span className="border-line"></span>
                              <Menu.Item key="userMenu8">
                                <a onClick={() => this.logoutUser(null)} className="header-menu" href="#" target="_blank">{plainTranslate(settings.locale, 'Sign in to another workspace')}</a>
                              </Menu.Item>
                            </SubMenu>
                        </Menu>
                        </Col>
                     
                  </Row>
                </header>

            {this.state.showNotificationMenu &&
                <ul className="dropdown-menu notification_menu header-menu" aria-labelledby="dropdownMenu1">
                  <div className="arrow-help border header-menu"></div>
                    <div className="arrow-help header-menu"></div>
                    <div className="notification-header header-menu">
                      <span className="username header-menu">{plainTranslate(settings.locale, 'Notifications')} &nbsp;
                      </span>
                    <span className="clear-all pull-right header-menu">
                      <Link className="header-menu" to={"#"} onClick={this.clearAllNotifications}>{plainTranslate(settings.locale, 'Clear all notifications')}</Link>
                    </span>
                    </div>
                      <div className="notification_array header-menu" style={{overflow: "scroll", height: 300}}>
                        {this.state.currentNotifications.length > 0 ?
                          <span className="header-menu">
                            {this.state.currentNotifications.map((notificationObj, i) => (
                              <li className="notif header-menu" key={i}>
                              <div className="header-menu" key={i}>
                                <div className="col-md-11 col-xs-11 notif-desc header-menu">
                                  {notificationObj.type == "Meeting" &&
                                    <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                  }
                                  {notificationObj.type !== "Meeting" &&
                                    <i className="fa fa-lightbulb-o unread header-menu"></i>
                                  }
                                 <span className="text-left header-menu">{notificationObj.name}</span>
                                </div>
                                <div className="col-md-1 col-xs-1 notif-i header-menu">
                                  <span className="notif-edit header-menu">
                                    <i className="ion-eye header-menu" id="notification-edit" title={plainTranslate(settings.locale, 'View')} onClick={() => this.openNotificationForm(notificationObj)}></i>
                                  </span>
                                  <span className="notif-edit header-menu">
                                    <i className="fa fa-trash header-menu" id="notification-delete" title={plainTranslate(settings.locale, 'Mark as read')} onClick={() => this.changeStatus(notificationObj)} style={{paddingTop: 4}}></i>
                                  </span>
                                </div>
                            </div>
                            <div className="col-md-12 header-menu">
                              <span>
                                {notificationObj.account && 
                                  <Link style={{marginRight: 10, marginRight: 5}} to={'/' +this.props.db + '/account/' + notificationObj.accountId + '/profile'}><UserOutlined style={{marginRight: 5}}/>{notificationObj.account}</Link>
                                }
                                {notificationObj.project && 
                                  <Link style={{marginRight: 10, marginRight: 5}} to={'/' +this.props.db + '/project/' + notificationObj.projectId + '/profile'}><HddOutlined style={{marginRight: 5}}/>{notificationObj.project}</Link>
                                }
                                {notificationObj.task && 
                                  <Link style={{marginRight: 10, marginRight: 5}} to={'/' +this.props.db + '/task/' + notificationObj.taskId + '/profile'}><ScheduleOutlined style={{marginRight: 5}}/>{notificationObj.task}</Link>
                                }
                              </span>
                            </div>
                            <div className="col-md-12 header-menu">
                              {notificationObj.type == "Meeting" &&
                                <p className="notif_date header-menu">{notificationObj.startDate} - {notificationObj.endDate}</p>
                              }
                              {notificationObj.type !== "Meeting" &&
                                <p className="notif_date header-menu">{notificationObj.activityDate}</p>
                              }
                            </div>
                            </li>
                            ))}
                            </span>

                            :
                              <li className="notif text-center header-menu"><strong>{plainTranslate(settings.locale, 'No notifications')}</strong></li>
                        }

                    </div>
                </ul>
            }
            {
                this.state.open &&
                <SlideModal onClose={() => this.closeNotificationForm()}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                {/* <SimpleForm
                                    saveRoute={('reminderId' in this.state.notification) ? (this.props.db + '/api/notifications/edit') : (this.props.db + '/api/notifications/new')}
                                    formRoute={this.props.db + '/api/notification/form/'}
                                    match={{params: {id: ('reminderId' in this.state.notification) ? this.state.notification.reminderId : null}}}
                                    onSuccessfulSave={() => this.updateNotifications()}
                                    popup={true}
                                    onCancel={() => this.closeNotificationForm()}
                                /> */}
                                <SimpleForm
                                    formRoute={this.props.db + '/api/activity/form/'}
                                    saveRoute={this.props.db + '/api/activities/edit'}
                                    match={{params: {id: this.state.notification }}}
                                    onSuccessfulSave={() => this.updateNotifications()}
                                    popup={true}
                                    onCancel={() => this.closeNotificationForm()}
                                    form={'initializeFromState'}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
            }
            <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
            {
                this.state.showShortcuts &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Keyboard shortcuts')}
                       onClose={() => this.setState({showShortcuts: false})}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">
                                    <div className="modal-body">
                                        <p>ctrl+m - {plainTranslate(this.props.settings.locale, 'Show keyboard shortcuts')}</p>
                                        <p>ctrl+up arrow - {plainTranslate(this.props.settings.locale, 'Find a CRM account')}</p>
                                        <p>ctrl+down arrow - {plainTranslate(this.props.settings.locale, 'Show the "Create New Record" menu')}</p>
                                        <p>shift+s - {plainTranslate(this.props.settings.locale, 'Save a new record')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            }
            {
                this.state.showNewMenu &&
                <Popup locale={settings.locale}
                       title={plainTranslate(settings.locale, 'New')}
                       onClose={() => this.setState({showNewMenu: false})}>
                    <section className="newPanel" style={{fontSize: 18}}>
                        <div className="panel-body" id="modal-body-new-menu">
                                <NewMenuContent settings={settings} close={() => this.setState({showNewMenu: false})}/>
                        </div>
                    </section>
                </Popup>
            }
            </div>
         );
     }
}

const mapStateToProps = state => ({
        settings: state.settings,
        notification: state.notification,
});

const mapDispatchToProps = dispatch => ({
    getCurrentNotifications: (db, id) => dispatch(getCurrentNotifications(db, id)),
    logoutUser: (db) => dispatch(logoutUser(db))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
