import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import { fetchSettings} from "../../../actions/actions";

class EcommerceSettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            };
        
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/ecommerce/settings/ecommerce-settings', 'ecommerceSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                       {/* <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Deal Form')}
                                            description={plainTranslate(this.props.settings.locale,'Set the default form for ecommerce deal')}
                                            component={'select'}
                                            value ={this.props.settings.ecommerceSettings}
                                            type={'ecommerceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                                            setting={'ecommerceDealForm'}
                                         />
                                         <hr/> */}
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Availability Locations')}
                                            description={plainTranslate(this.props.settings.locale, "Set availability locations")}
                                            component={'multi_select'}
                                            value ={this.props.settings.ecommerceSettings}
                                            type={'ecommerceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                                            setting={'ecommerceAvailabilityLocations'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Emails on sale status change')}
                                            description={plainTranslate(this.props.settings.locale, "Set diffrent email template on change online sale status")}
                                            component={'double_select'}
                                            value ={this.props.settings.ecommerceSettings}
                                            type={'ecommerceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                                            setting={'ecommerceSaleStatusEmail'}
                                            secondSetting={'ecommerceSaleEmailTemplate'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Scale Of Decimal')}
                                            description={plainTranslate(this.props.settings.locale, "Number of digits to the right of the decimal point - for prices in ecommerce")}
                                            component={'input'}
                                            value ={this.props.settings.ecommerceSettings}
                                            type={'ecommerceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                                            setting={'ecommerceDecimalPrice'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Url for forgotten password')}
                                            description={plainTranslate(this.props.settings.locale, "Set url for forgotten password")}
                                            component={'input'}
                                            value ={this.props.settings.ecommerceSettings}
                                            type={'ecommerceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                                            setting={'ecommerceForgottenPasswordUrl'}
                                         />
                                          <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'MyPos settings')}
                                            description={plainTranslate(this.props.settings.locale, "Settings for MyPos integrations")}
                                            component={'mypos_settings'}
                                            value ={this.props.settings.ecommerceSettings}
                                            type={'ecommerceSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/ecommerce/save/settings/individual'}
                                            setting={'ecommerceMyPos'}
                                         />
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EcommerceSettingsPage);
