import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Table, Pagination, Menu, Dropdown, Avatar, Input, Button, Space, DatePicker as DatePickerComponent, Drawer, Switch, Tooltip, Select, Image, Row, Col, Modal, Skeleton } from 'antd';
import { LoadingOutlined, DownOutlined, UserOutlined, SearchOutlined, LeftOutlined, RightOutlined, SettingOutlined, ReloadOutlined, DownloadOutlined,SaveOutlined,LinkOutlined, DatabaseOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import GridHeader from './gridHeader.jsx'
import Translate,{ plainTranslate } from '../common/translate';
import {Link} from 'react-router-dom';
import { onGridMountV2, gridV2FetchData, onGridV2ExportClick, onCellActionTrigger, onConfirmActionTrigger } from "../../actions/actions";
import { GRID_CONFIRM_DIALOG_SHOW, } from "../../constants/actionTypes";
import moment from 'moment';
import "./gridV2.css";
import Columns from './columns';
import BulkActions from './bulkActions';
import SalesProfile from "../profiles/salesProfile.jsx";
import TaskProfile from "../profiles/taskProfile.jsx";
import InvoiceProfile from "../profiles/invoiceProfile.jsx";
import ItemProfile from "../profiles/item/itemProfile";
import InventoryProfile from "../profiles/inventoryProfile.jsx";
import ExpensesProfile from "../profiles/expensesProfile.jsx";
import ProjectProfile from "../profiles/projectProfile.jsx";
import DealProfile from "../profiles/dealProfile.jsx";
import AssetProfile from "../profiles/assetProfile.jsx";
import PaymentLedger from "../profiles/paymentLedger.jsx";
import MessageProfile from "../profiles/messageProfile.jsx";
import Profile from "../crm/accounts/profile/profile.jsx";
import InitializeFromStateForm from '../redux-forms/form';
import SaveViewDrawer from "./components/save-view.jsx";
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import axios from "axios";
import ModalConfirm from '../common/ModalConfirm';
import InventoryPlacesPopup from "./components/inventory-places-popup.jsx";

const profiles = {
  sale: SalesProfile,
  task: TaskProfile,
  invoice: InvoiceProfile,
  item: ItemProfile,
  inventory: InventoryProfile,
  expense: ExpensesProfile,
  project: ProjectProfile,
  deal: DealProfile,
  message: MessageProfile,
  asset: AssetProfile,
  account: Profile,
  paymentLedger: PaymentLedger
};

const { RangePicker } = DatePickerComponent;
const { SubMenu } = Menu;
const { Option } = Select;

const GridV2 = (props, state) => {
  const urlParams = new URLSearchParams(props.location.search);

  const [loading, setLoading] = useState(true);
  const [colLoading, setColLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [gridSelectedRowKeys, setGridSelectedRowKeys] = useState([]);
  const [gridSelectedRows, setGridSelectedRows] = useState([]);
  const [gridDeSelectedRows, setGridDeSelectedRows] = useState([]);
  const [params, setParams] = useState({});
  const [startSelected, setStartSelected] = useState(null);
  const [endSelected, setEndSelected] = useState(null);
  const [openDatepicker, setOpenDatepicker] = useState(false);
  const [gridDataRoute, setGridDataRoute] = useState();
  const [gridExportRoute, setGridExportRoute] = useState();
  const [gridRoute, setGridRoute] = useState();
  const [gridSaveRoute, setGridSaveRoute] = useState();
  const [gridSelectAll, setSelectAll] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openedPopup, setOpenedPopup] = useState(null);
  const [id, setId] = useState(null);
  const [gridId, setGridId] = useState(null);
  const [refs, setRefs] = useState(props.match.params.refs);
  const [cancelDocumentsPopup, setCancelDocumentsPopup] = useState(null);
  const [linkedDocumentsData, setLinkedDocumentsData] = useState(null);
  const [linkedDocumentsStages, setLinkedDocumentsStages] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [popupLoading, setPopupLoading] = useState(false);
  const cancelToken = useRef(null);
  const [inventoryPlacesPopup, setInventoryPlacesPopup] = useState(null);
  const [showImagesPopup, setShowImagesPopup] = useState(false);
  const [imagesPopupData, setImagesPopupData] = useState(null);
  let inputTimer;
  const isInitialMount = useRef(true);

  params.limit = pageSize;
  if(!params.startDate){
    let start = null;
    if (localStorage.getItem('grid2_startDate')) {
        start = moment(localStorage.getItem('grid2_startDate'));
    }else {
        start = moment();
    }
    params.startDate = moment(start).format('YYYY-MM-DD');
  }

  if(!params.endDate){
    let end = null;
    if (localStorage.getItem('grid2_endDate')) {
        end = moment(localStorage.getItem('grid2_endDate'));
    }else {
        end = moment();
    }
    params.endDate = moment(end).format('YYYY-MM-DD')+ ' 23:59:59';
  }

  function generateLink(link, params, row, isExternal = false){
    let url;
    if (isExternal) {
      url = link;
    } else {
      url = '/'+ props.match.params.db + link;
    }

    params.map(param => {
      if (row && param.source in row) {
        url = url.replace('{' + param.target + '}', row[param.source]);
      }
    });
    return url;
  }

  function updateGridData(resetPagination=true){
    cancelToken.current = axios.CancelToken.source();
    setLoading(true);
    if(resetPagination){
      setCurrentPage(1);
      params.offset = 0;
      delete params['totalRecords'];
      delete params['pinnedRows'];
      setGridSelectedRowKeys([]);
      setGridSelectedRows([]);
      setGridDeSelectedRows([]);
      setSelectAll(false);
    }else{
      if(props.totalRecords){
        params.totalRecords = props.totalRecords;
        params.pinnedRows = props.pinnedRows;
      }
    }

    props.gridV2FetchData(gridDataRoute, params, cancelToken.current.token).then(() => {
      setLoading(false);
    });
  }

  function updateGridColumns(data=null){
    setColLoading(true);
    props.onGridMountV2(gridRoute, data).then(() => {
      setColLoading(false);
    });
  }

  function onDeleteAction(button,record){
      props.onCellActionTrigger(button, null , generateLink(button.url, button.params, record));
  }

  function openConfirmDialog() {
    props.onConfirmDialogShow();
  }

  function acceptConfirmDialog() {
    props.onConfirmActionTrigger(props.gridProperties.confirm.payload).then(response =>
       updateGridData()
    );
  }

  const rowSelection = {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if(gridSelectAll){
          if(selected){
            let index = gridDeSelectedRows.findIndex(o => o.ID === record.ID);
            if(index >= 0){
              gridDeSelectedRows.splice(index, 1);
            }
            setGridDeSelectedRows(gridDeSelectedRows.filter(item => item !== record.ID));
            setGridSelectedRowKeys(gridSelectedRowKeys => [...gridSelectedRowKeys, record.key]);
          }else{
            setGridDeSelectedRows(gridDeSelectedRows => [...gridDeSelectedRows, record.ID]);
            setGridSelectedRowKeys(gridSelectedRowKeys.filter(item => item !== record.key));
          }
        }else{
          if(selected){
            setGridSelectedRows(gridSelectedRows => [...gridSelectedRows, record.ID]);
            setGridSelectedRowKeys(gridSelectedRowKeys => [...gridSelectedRowKeys, record.key]);
          }else{
            let index = gridSelectedRows.findIndex(o => o === record.ID);
            if(index >= 0){
              gridSelectedRows.splice(index, 1);
            }
            setGridSelectedRowKeys(gridSelectedRowKeys.filter(item => item !== record.key));
          }
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        if(selected){
          setSelectAll(true);
          setGridSelectedRows(gridSelectedRows.concat(changeRows.map(a => a.ID)));
          setGridSelectedRowKeys(gridSelectedRowKeys.concat(changeRows.map(a => a.key)));
          setGridDeSelectedRows([]);
        }else{
          setGridSelectedRows([]);
          setGridSelectedRowKeys([]);
          setSelectAll(false);
        }
      }
  };

  function onChange(pagination, filters, sorter, extra) {
    let resetPagination = true;
    if(sorter){
      let sort = [];

      if(Array.isArray(sorter)){
        sorter.map((val,key) =>{
          sort.push(val.column.orderBy + ' ' +  (val.order === "ascend" ? val.order.substring(0,3) : val.order.substring(0,4)));
        })
      }else{
        if(sorter.column){
          sort.push(sorter.column.orderBy + ' ' +  (sorter.order === "ascend" ? sorter.order.substring(0,3) : sorter.order.substring(0,4)));
        }
      }
      params.orderBy = sort.join(',');
      resetPagination = false;
    }
    if(pagination){
      params.offset = pagination.current-1;
      setCurrentPage(pagination.current);
      resetPagination = false;
    }
    if(Object.keys(filters).length > 0){
        let columnFilters = [];
        Object.keys(filters).forEach(function(key) {
            if(filters[key] !==null){
                columnFilters.push({title: columns[key].dataIndex, column: columns[key].column, value: filters[key][0], action: 'like'});
            }
        });
        params.columnFilters = columnFilters;
        if(extra.action === "filter"){
          resetPagination = true;
        }
    }

    updateGridData(resetPagination);
  }

  function onCustomFilterChange(data, selectAll, searchValue){

    //let keys = Object.assign({}, data);

    params.customFilters = data;
   
    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
      updateGridData();
      }, 2000);
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
  };

  function handleReset(clearFilters){
    clearFilters();
  };

  const getColumnSearchProps = col => (
  col.dataIndex !== 'ID' && !col.isAdditionalData &&
  {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={(plainTranslate(props.settings.locale,'Search...'))}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, col.dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, col.dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, backgroundColor: "#44c699", borderColor: "#44c699" }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {plainTranslate(props.settings.locale, "Reset")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  });

  function onCalendarOpen(open){
    setOpenDatepicker(open);
  }
  function onCalendarChange(dates, dateStrings, range){

    if(range.range === 'start'){
      setStartSelected(true);
    }
    if(range.range === 'end'){
      setEndSelected(true);
    }

    let start = dates[0] ? dates[0] : null;
    let end = dates[1] ? dates[1] : null;
    let daysDiff = (moment(end).diff(moment(start), 'days'));

    if (daysDiff < 31) {
        localStorage.setItem('grid2_startDate', moment(start));
        localStorage.setItem('grid2_endDate', moment(end));
    }

    if(start){            
        start = dates[0].format('YYYY-MM-DD')
    }
    if(end){            
        end = dates[1].format('YYYY-MM-DD')+ ' 23:59:59';
    }  

    params.startDate = start;
    params.endDate = end;
    setParams(params);
  }

  function onGridSearchChange(e){
    
    params.searchValue = e.target.value;

    clearTimeout(inputTimer);
    inputTimer = setTimeout(() => {
    updateGridData();
    }, 2000);
  }

  function onChangeColumns(data){
    params.columns = data;
    updateGridColumns(data);
    updateGridData();
  }

  function openPopup(id, type){
    setId(id);
    setVisible(true);
    setOpenedPopup(type);
  }

  function closePopup(){
    //props.match.params.id = null;
    setVisible(false);
    setOpenedPopup(null);
    setCancelDocumentsPopup(false);
    setLinkedDocumentsData(null);
    setLinkedDocumentsStages(null);
    setShowImagesPopup(false);
    setImagesPopupData(false);
  }

  function handleExport(type, template = null){
    let cols = [];

    columns.map(col => {
      if(col['dataIndex'] !== 'ID'){
        cols.push(col['dataIndex']);
      }
    })

    let data = gridSelectedRows;

    if(gridSelectAll){
      data = gridDeSelectedRows;
    }

    let route = gridExportRoute + type;

    if(type === "importTemplate"){
      route = '/' + props.match.params.db + props.exportTemplateRoute;
    }

      if(gridId){
        route += '?grid='+gridId;
        if(props.match.params.refs){
          route += '&refs='+props.match.params.refs;
        }
      }else{
        if(props.match.params.refs){
          route += '?refs='+props.match.params.refs;
        }
      }

    props.onGridV2ExportClick(route, params, template, data, cols, gridSelectAll);
  }

  function getPopupContent(){
    props.match.params.id = id;
    let row = null;
      if (openedPopup.secondId && props.data.length) {
        row = props.data.find((item => item['ID'] == props.match.params.id));
      }

    if(openedPopup.component === 'profile'){
      const ComponentName = profiles[openedPopup.type];
      return <ComponentName {...props} gridPopup={true} updateGridData={updateGridData} secondId={row ? row[openedPopup.secondId] : props.match.params.id}/>
    }else if(openedPopup.component === 'edit'){  
      return <InitializeFromStateForm 
                {...props} 
                closePopup={closePopup} 
                onSuccessfulSave={updateGridData} 
                gridpopup={true} form={'initializeFromState2'} 
                isNew={true} saveRoute={openedPopup.hasOwnProperty('saveRoute') ? openedPopup.saveRoute : openedPopup.link} 
                secondId={row ? row[openedPopup.secondId] : props.match.params.id}
                formRoute={openedPopup.link} />
    }else if(openedPopup.component === 'inventoryPlaces'){
      return <InventoryPlacesPopup db={props.match.params.db} closePopup={closePopup} id={props.match.params.id} updateGridData={updateGridData}/>
    }
  }

  function onRowDropdownClick(option,record){
    if(option.cancel){
      client.post('/' + props.match.params.db + '/api/check-documents/sales', {'body': [record.ID]})
      .then(response => response.data)
      .then(response => {
          if(response.linkedDocuments.length > 0){
            setCancelDocumentsPopup(record);
            setLinkedDocumentsData(response);
            setSelectedOption(option);
          }else{
            let url = generateLink(option.link.url, option.link.params, record);
            client.post(url)
            .then(response => response.data)
            .then(response => {
              if ('errors' in response) {
                showAToast(plainTranslate(props.settings.locale, response.errors), 'errSettings', 'error');
              }
              else {
                showAToast(plainTranslate(props.settings.locale, response.success), 'succSettings', 'success');
                updateGridData(false);
              }
            });
          }
      });
    }else{
      let url = generateLink(option.link.url, option.link.params, record);
      client.post(url)
      .then(response => response.data)
      .then(response => {
        if ('errors' in response) {
          showAToast(plainTranslate(props.settings.locale, response.errors), 'errSettings', 'error');
        }
        else {
          showAToast(plainTranslate(props.settings.locale, response.success), 'succSettings', 'success');
          updateGridData(false);
        }
      });
    }
  }

  function onCopyLink(button,record){
    navigator.clipboard.writeText(window.location.origin + generateLink(button.tooltip.url, button.tooltip.params, record))
    showAToast(button.tooltip.label ? plainTranslate(props.settings.locale, 'Link copied!') : plainTranslate(props.settings.locale, 'Profile link copied!'), 'succSettings', 'success');
  }

  function showImages(route){
    setShowImagesPopup(true);
    setPopupLoading(true);
    client.get(route)
    .then(response => response.data)
    .then(response => {
      setImagesPopupData(response.data ? response.data : response);
      setPopupLoading(false);
    })
  }

  function getImagesPopupContent(){
      return <div>
        <Skeleton loading={popupLoading} />
        {!popupLoading &&
          <Row type="flex" justify="start" className="dir_row">
          {imagesPopupData && imagesPopupData.map((image, key) => {
            return <Col xs={24} sm={12} md={8} lg={6} xl={6} key={key} className="dir">
              <Image.PreviewGroup>
                <Image style={{height: 150}} width={200} src={image.full}/>
              </Image.PreviewGroup>
            </Col>
          })}
          {(!imagesPopupData || imagesPopupData.length === 0) &&
            <h3>Data not found </h3>
          }
          </Row>
        }
      </div>
    
  }

  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    client.post('/'+props.match.params.db + props.gridRoute + '/get-user-view')
    .then(response => response.data)
    .then(response =>{
      
      if(response.gridId){
        setGridRoute('/'+props.match.params.db + props.gridRoute +'?grid='+response.gridId + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));
        setGridDataRoute('/'+props.match.params.db + props.gridDataRoute +'?grid='+response.gridId + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));
        setGridExportRoute('/'+props.match.params.db + props.gridExportRoute);
        setGridSaveRoute('/'+props.match.params.db + props.gridSaveRoute +'?grid='+response.gridId + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));
      }else{
        setGridRoute('/'+props.match.params.db + props.gridRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
        setGridDataRoute('/'+props.match.params.db + props.gridDataRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
        setGridExportRoute('/'+props.match.params.db + props.gridExportRoute);
        setGridSaveRoute('/'+props.match.params.db + props.gridSaveRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
      }
      setGridId(response.gridId);
    })

    if (props.title) {
        let titleArr = props.title;
        titleArr.map((item, key) => titleArr[key] = plainTranslate(props.settings.locale, item));
        document.title = titleArr.join(' ');
    }
    return () => {
      cancelToken.current.cancel()
    }
  }, []);

  useEffect(() => {
    let id = urlParams.get('grid');
    if(id){
      setGridRoute('/'+props.match.params.db + props.gridRoute +'?grid='+ id + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));;
      setGridDataRoute('/'+props.match.params.db + props.gridDataRoute +'?grid='+id + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));;
      setGridExportRoute('/'+props.match.params.db + props.gridExportRoute);
      setGridSaveRoute('/'+props.match.params.db + props.gridSaveRoute +'?grid='+id + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));;
    }else{
      setGridRoute('/'+props.match.params.db + props.gridRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
      setGridDataRoute('/'+props.match.params.db + props.gridDataRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
      setGridExportRoute('/'+props.match.params.db + props.gridExportRoute);
      setGridSaveRoute('/'+props.match.params.db + props.gridSaveRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
    }

    setGridId(urlParams.get('grid'));
  }, [urlParams.get('grid')]);

  useEffect(() => {
    let id = urlParams.get('grid');
    if(id){
      setGridRoute('/'+props.match.params.db + props.gridRoute +'?grid='+id + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));;
      setGridDataRoute('/'+props.match.params.db + props.gridDataRoute +'?grid='+id + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));;
      setGridExportRoute('/'+props.match.params.db + props.gridExportRoute);
      setGridSaveRoute('/'+props.match.params.db + props.gridSaveRoute +'?grid='+id + (props.match.params.refs ? '&refs='+props.match.params.refs : ''));;
    }else{
      setGridRoute('/'+props.match.params.db + props.gridRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
      setGridDataRoute('/'+props.match.params.db + props.gridDataRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
      setGridExportRoute('/'+props.match.params.db + props.gridExportRoute);
      setGridSaveRoute('/'+props.match.params.db + props.gridSaveRoute + (props.match.params.refs ? '?refs='+props.match.params.refs : ''));
    }

    setRefs(props.match.params.refs);
  }, [props.match.params.refs]);

    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      }else {
        delete params['customFilters'];
        if(gridId){
          props.history.push(props.location.pathname+'?grid='+gridId);
        }
        updateGridColumns();
        updateGridData();
      }

  }, [gridId, refs]);

  useEffect(() => {
    if((startSelected || endSelected) && !openDatepicker){
      updateGridData();
      setStartSelected(null);
      setEndSelected(null);
    }
  }, [startSelected,endSelected]);

  useEffect(() => {
    if(gridSelectAll){
      let newRows = [];

      props.data.map(row => {
        let index = gridSelectedRows.findIndex(o => o === row.ID);
        if(index < 0){
          newRows.push(row);
        }
      })

      setGridSelectedRows(gridSelectedRows.concat(newRows.map(a => a.ID)));
      setGridSelectedRowKeys(gridSelectedRowKeys.concat(newRows.map(a => a.key)));
    }
  }, [props.data]);

    useEffect(() => {
      let cols = [];

      let i=0;
      props.gridProperties.columns && props.gridProperties.columns.map((col,key) =>{
        if(!col.hide){
          let obj = {
            key: i,
            dataIndex: col.dataIndex,
            title: plainTranslate(props.settings.locale, col.title),
            width: col.width ? col.width : null,
            fixed: col.fixed,
            sorter: (col.dataIndex !== 'ID' && !col.isAdditionalData) ? {multiple:key} : false,
            column: col.column,
            showSorterTooltip: false,
            ellipsis: true,
            orderBy: col.orderBy,
            isAdditionalData: col.isAdditionalData,
            ...getColumnSearchProps(col)

          };

          if(col.type === 'text'){
            obj.render= function(text, record){
              return(
                col.icon && text ?  <span><Avatar src={record[col.icon]} icon={<UserOutlined />} /> {text}</span> : <span style={{background: col.colorProperty ? record[col.colorProperty] : 'custom', border: col.colorProperty ? record[col.colorProperty] : 'custom'}} className={col.className ? record[col.className] ? record[col.className].replace(/\s/g, '') : '' : ''}>{col.colorProperty ? plainTranslate(props.settings.locale, text) : text}</span>
              )
            }
          }else if(col.type === 'date'){
            obj.render= function(text, record){
              let value = text;
              let dot = 0;
              let dash = 0;
              if (value) {
                  dot = value.indexOf(".");
                  dash = value.indexOf("-");
              }
              return(
                <div>{text ? dot > 1 ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY').format(props.settings.dateFormat) : dash > 1 ? moment((text).replaceAll('-', '/'), 'YYYY-MM-DD').format(props.settings.dateFormat) : '' : ''}</div>
              )
            }
          }else if(col.type === 'number'){
            obj.render= function(text, record){
              return(
                <div style={{textAlign:'right'}}>{text}</div>
              )
            }
          }else if(col.type === 'images'){
            obj.render= function(text, record){
              //if(record[col.dataIndex] > 0){
                return(
                    <div onClick={() => showImages(generateLink(col.link.url, col.link.params, record))} style={{cursor: 'pointer', textDecoration: 'underline'}}>{plainTranslate(props.settings.locale, text)}</div>
                )
/*              }else{
                return (<div></div>)
              }*/
            }
          }else if(col.type === 'link'){
            obj.render= function(text, record){
              if(col.link.hasOwnProperty('redirect')){
                return <a href={generateLink(col.link.url, col.link.params, record)} target="_blank">{text}</a>
              }else{
                return <Link to={generateLink(col.link.url, col.link.params, record)}>{text}</Link>
              }
            }
          }
          else if(col.type === 'actions'){
            obj.render= function(text, record){
              const buttons = (
                  col.buttons && col.buttons.map(button => {
                    let hideButton = false;
                    if (button.hasOwnProperty('checkPermissions') && record?.[button.checkPermissions] === false) {
                      hideButton = true;
                    }
                    
                    if(button.hasOwnProperty('popup')){
                      return <i className={'grid-icon ' + button.icon} style={{margin: '0 5px', cursor: 'pointer', display: hideButton ? 'none' : 'initial'}} onClick={() => openPopup(record.ID, button.popup)}></i>
                    }else if(button.hasOwnProperty('redirect')){
                      let isExternal = false;
                      if(button.hasOwnProperty('isExternal')){
                        isExternal = button.isExternal;
                      }
                      return <a style={{display: hideButton ? 'none' : 'initial'}} href={generateLink(button.link.url, button.link.params, record, isExternal)} target="_blank"><i className={'grid-icon ' + button.icon} style={{margin: '0 5px'}}></i></a>
                    }else if(button.hasOwnProperty('dropdown')){
                      const menu = (
                        <Menu>
                        {button.dropdown && button.dropdown.map(value => {
                          if(value.source){
                            return <SubMenu title={value.label}>
                              {col[value.source].map(option => {
                                if(!value.hasOwnProperty('dependency') || (value.hasOwnProperty('dependency') && record?.[value.dependency] === option.type)){
                                  if(value.redirect){
                                    return <Menu.Item><a href={generateLink(option.url, option.params, record)} target="_blank">{option.label}</a></Menu.Item>
                                  }else{
                                    return <Menu.Item onClick={() => onRowDropdownClick(option, record)}>{option.label}</Menu.Item>
                                  }
                                }
                              })}
                            </SubMenu>
                          }else{
                            if(value.confirm){
                              return <Menu.Item onClick={() => onRowDropdownClick(value, record)}>{value.label}</Menu.Item>
                            }else{
                              return <Menu.Item><Link to={generateLink(value.link.url, value.link.params, record)}>{value.label}</Link></Menu.Item>
                            }
                          }
                        })}
                        </Menu>
                      );
                     return <Dropdown overlay={menu}>
                        <span style={{padding: 5}}>
                          <i className={'grid-icon ' + button.icon} style={{margin: '0 5px', cursor: 'pointer'}}></i>
                        </span>
                      </Dropdown>
                    }else if(button.hasOwnProperty('link')){
                      return <Link style={{display: hideButton ? 'none' : 'initial'}} to={generateLink(button.link.url, button.link.params, record)}><i className={'grid-icon ' + button.icon} style={{margin: '0 5px'}}></i></Link>
                    }else if(button.hasOwnProperty('delete')){
                      return <a style={{display: hideButton ? 'none' : 'initial'}} onClick={() => onDeleteAction(button,record)} className="marg" key={key}>
                        <i className={'grid-icon ' + button.icon} aria-hidden="true"></i>
                      </a>
                    }else if(button.hasOwnProperty('tooltip')){
                      return  <Tooltip placement="bottom" title={button.tooltip.label ? plainTranslate(props.settings.locale, button.tooltip.label) : plainTranslate(props.settings.locale, 'Copy profile link')}>
                         <span><LinkOutlined className={'grid-icon'} onClick={() => onCopyLink(button,record)}/></span>
                        </Tooltip>
                    }
                  })
                );

              return (
                <span className="table-actions">
                    {buttons}
                </span>
               )
            }
          }else if(col.type === 'dropdown'){
            obj.render= function(text, record){
              const dropdownOptions = (
                <Menu>
                    {col.options && col.options.map((option, i) => (
                            <Menu.Item key={i} disabled ={option.forbiddenAccess} onClick={() => onRowDropdownClick(option, record)}>
                              {option.label}
                            </Menu.Item>
                        ))
                    }
                </Menu>
              );
              return(<span style={{background: col.colorProperty ? record?.[col.colorProperty] : 'initial', border: col?.colorProperty ? record?.[col.colorProperty] : 'initial' }} className={col.className ? record?.[col.className] ? record?.[col.className].replace(/\s/g, '') : '' : ''}>
                  <span style={{whiteSpace: "nowrap"}}>{text}</span>
                    {col.options.length > 0 &&
                      <Dropdown overlay={dropdownOptions}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                          <DownOutlined />
                        </a>
                      </Dropdown>
                    }
              </span>)
              }
          }
          cols.push(obj);
          i++;
        }
      })
      setColumns(cols);

  }, [props.gridProperties.columns]);

    function getLinkedDocumentsContent(){
        const columns = [
            {
                title: plainTranslate(props.settings.locale, 'Sale No'),
                dataIndex: 'saleNo',
                key: 'saleNo',
            },
            {
                title: plainTranslate(props.settings.locale, 'Document Type'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: plainTranslate(props.settings.locale, 'Document No'),
                dataIndex: 'no',
                key: 'no',
            },
            {
                title: plainTranslate(props.settings.locale, 'Document Date'),
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: plainTranslate(props.settings.locale, 'Current Stage'),
                dataIndex: 'stage',
                key: 'stage',
                render: (text, record) => {
                    let stages = [];
                    if(record.type === 'payment'){
                        stages = linkedDocumentsData.paymentStagesOptions;
                    }else if(record.type === 'inventory receipt'){
                        stages = linkedDocumentsData.inventoryStagesOptions;
                    }else if(record.type === 'item request'){
                        stages = linkedDocumentsData.itemRequestStagesOptions;
                    }
                    return <Select dropdownMatchSelectWidth={false} defaultValue={record.stage} onChange={(value,type, id) => linkedDocumentsChangeStage(value, record)}>
                        {stages.map(stage => {
                            return <Option disabled={stage.position <= record.stagePosition ? true : false} className={"linked-documents-table"} value={stage.value}>{stage.label}</Option>
                        })}
                    </Select>
                }
            }];

        return <div><p>This sale is linked by:</p>
            <p>*choose a status for linked documents</p>
            <Table loading={popupLoading} dataSource={linkedDocumentsData ? linkedDocumentsData.linkedDocuments : null} columns={columns} pagination={false} />
            <p>*if payment document contains more than one document, the line with this sale will be deleted.</p>
            <button className="button-primary-edited text-center btn-ok"
                    onClick={() => changeStage()}>
                <Translate locale={props.settings.locale} value="Confirm"/>
            </button>
            <button className="button-outlines green text-center"
                    onClick={() => closePopup()}>
                <Translate locale={props.settings.locale} value="Cancel"/>
            </button>
            </div>
    }

    function linkedDocumentsChangeStage(value, record) {
        let arr = Object.assign({}, linkedDocumentsStages);
        arr[record.id] = {'url':record.url + value, 'saleId': record.saleId};
        setLinkedDocumentsStages(arr);
    }

    function changeStage(value){
      let changedStages = linkedDocumentsStages;
      setPopupLoading(true);
      for (const property in changedStages) {
        client.post('/' + props.match.params.db + changedStages[property].url, {'body':changedStages[property].saleId})
          .then(response => response.data)
          .then(response => {
              if ('errors' in response) {
                  showAToast(response.errors, 'baseError', 'error');
              }
          })
          .catch(err => {
              showAToast('Error!', 'baseError', 'error')
          });
      }

      let url = generateLink(selectedOption.link.url, selectedOption.link.params, cancelDocumentsPopup);
      client.post(url)
      .then(response => response.data)
      .then(response => {
        if ('errors' in response) {
          showAToast(plainTranslate(props.settings.locale, response.errors), 'errSettings', 'error');
        }
        else {
          showAToast(plainTranslate(props.settings.locale, response.success), 'succSettings', 'success');
          updateGridData(false);
          setPopupLoading(false);
          closePopup();
        }
      });
    }

/*  useEffect(() => {
    if(props.gridProperties.header){
      params.customFilters = props.gridProperties.header.customFilters;
    }

  }, [props.gridProperties.header]);*/

    const exportOptions = (
      <Menu>
        <Menu.Item key="0">
          <a href="#" onClick={(e) => handleExport('csv')} className='submenus-link'>
              {plainTranslate(props.settings.locale, 'CSV')}
          </a>
        </Menu.Item>
        <Menu.Item key="1">
          <a href="#" onClick={(e) => handleExport('excel')} className='submenus-link'>
              {plainTranslate(props.settings.locale, 'Excel')}
          </a>
        </Menu.Item>
        {props.gridProperties.options && props.gridProperties.options.exportTemplates && props.gridProperties.options.exportTemplates.map((action, key) => {
            return <Menu.Item key={key+2}>
                <a href="#" onClick={(e) => handleExport(action.value)} className='submenus-link'>
                    {plainTranslate(props.settings.locale, action.label)}
                </a>
            </Menu.Item>
        })}
      </Menu>
    );

    const importExportOptions = (
      <Menu>
        {props.exportTemplates && props.exportTemplates.map((action, key) => {
            return <Menu.Item key={key+2}>
                <a href="#" onClick={(e) => handleExport("importTemplate", action)} className='submenus-link'>
                    {plainTranslate(props.settings.locale, action)}
                </a>
            </Menu.Item>
        })}
      </Menu>
    );
    

    return (<div className= "gridV2">
        {
          props.gridProperties.confirm && props.gridProperties.confirm.show &&
          <ModalConfirm onClose={openConfirmDialog}>
              <section className="newPanel">
                  <div className="panel-body">
                      <div className="row m-bot15">
                          <div className="text-center">
                              <h3><strong>
                                 {plainTranslate(props.settings.locale, "Are you sure?")}
                              </strong>
                              </h3>
                              <div className="buttons">
                                  <button className="button-outline grey text-center"
                                      onClick={openConfirmDialog}>
                                      {plainTranslate(props.settings.locale, "Cancel")}
                                  </button>
                                  <button className="button-primary text-center btn-ok"
                                      onClick={acceptConfirmDialog}>
                                      {plainTranslate(props.settings.locale, "Yes")}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </ModalConfirm>
        }
        {
          cancelDocumentsPopup &&
          <ModalConfirm onClose={() => closePopup()}>
              <section className="newPanel">
                  <div className="row m-bot15">
                  {getLinkedDocumentsContent()}
                  </div>
              </section>
          </ModalConfirm>
        }
        {
          showImagesPopup &&
            <Modal width={1000} onCancel={() => closePopup()} footer={null} title={plainTranslate(props.settings.locale, 'Images')} visible={showImagesPopup}>
                <section className="newPanel">
                    <div className="row m-bot15">
                    {getImagesPopupContent()}
                    </div>
                </section>
            </Modal>
        }
        {props.gridProperties && props.gridProperties.header && <GridHeader refsId={refs} gridId={gridId} gridProperties={props.gridProperties} gridHeader={props.gridProperties.header} params={params} onCustomFilterChange={onCustomFilterChange} onGridSearchChange={onGridSearchChange}/>}
        <div style={{backgroundColor: 'white', height: "100%"}} >
            <div className="col-md-12 col-xs-12" style={{paddingTop:10, paddingBottom: 15, fontSize: 16, color: "#000"}}>
                <div className="col-md-3 col-xs-12 col-md-push-2 grid-datepciker">
                  {props.gridProperties.options && props.gridProperties.options.enableDatepicker &&
                    <RangePicker
                      disabled={(loading || colLoading) ? true : false}
                      bordered={true}
                      defaultValue={[moment(params.startDate), moment(params.endDate)]}
                      onCalendarChange={onCalendarChange}
                      onOpenChange={onCalendarOpen}
                      //format={'DD.MM.YYYY'}
                      format={props.settings.dateFormat}
                      disabledDate={ d => {
                          return false;
                      }}
                      separator={<span style={{display: "flex", fontSize: "9px", left: "-10px", position: "relative"}}><LeftOutlined /><RightOutlined /></span>}
                      ranges={{
                          'Today': [moment(), moment()],
                          'This week': [moment().subtract(1, 'weeks'), moment()],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                          'Last Month': [moment().subtract(30, 'days'), moment()],
                      }}
                    />
                  }
                </div>
                <div className="col-md-2 col-xs-5 col-md-pull-3" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  {props.gridProperties.bulkActions && props.gridProperties.bulkActions.length > 0 &&
                      <BulkActions actions={props.gridProperties.bulkActions} gridSelectAll={gridSelectAll} selectedRows={gridSelectedRows} deSelectedRows={gridDeSelectedRows} filters={params} updateGridData={updateGridData} gridId={gridId}/>
                  }
                </div>
                <div className="col-md-2 col-xs-3">
                </div>
                <div className="col-md-3 col-xs-4 grid-search-field">
                  {props.gridProperties.options && props.gridProperties.options.enableQuickFilter &&
                    <Input
                      size="small"
                      allowClear
                      onChange={onGridSearchChange}
                      prefix={<SearchOutlined />}
                    />
                  }
                </div>
                <div className={"col-md-2 col-xs-7" + (props.gridProperties.bulkActions && props.gridProperties.bulkActions.length > 0 ? '' : ' col-xs-push-5 col-sm-push-0')} style={{textAlign: "right", paddingTop: 4}}>
                  {props.gridProperties.options && props.gridProperties.options.showExportOptions !== false &&
                     <Dropdown overlay={exportOptions}>
                        <span style={{marginLeft: 15}} className="grid-icon"> <DownloadOutlined /></span>
                      </Dropdown>
                  }
                  {props.exportTemplates && 
                    /*<span style={{marginLeft: 15}} className="grid-icon" onClick={(e) => handleExport('importTemplate')}>
                      <DatabaseOutlined />
                    </span>*/
                      <Dropdown overlay={importExportOptions}>
                        <span style={{marginLeft: 15}} className="grid-icon"> <DatabaseOutlined /></span>
                      </Dropdown>
                  }
                  {props.gridProperties.options && <span style={{marginLeft: 15}} className="grid-icon"><ReloadOutlined onClick={() => updateGridData(true)} /></span>}
                  {props.gridProperties.options && props.gridProperties.options.enableColumns &&
                    <span style={{marginLeft: 15}} className="grid-icon">
                      <Columns columns={props.gridProperties.columns} onChangeColumns={onChangeColumns}/>
                    </span>
                  }
                  {props.gridProperties.options &&
                    <span style={{marginLeft: 15}} className="grid-icon">
                      <SaveViewDrawer gridRoute={gridRoute} gridSaveRoute={gridSaveRoute} columns={columns} params={params} gridId={gridId}/>
                    </span>
                  }
                  {props.gridProperties.options && props.gridProperties.options.enableGridSettings && props.settings.roles.includes('ROLE_SUPER_ADMIN') &&
                    <span style={{marginLeft: 15}} className="grid-icon"><Link to={props.gridProperties.header.settingsPath}><SettingOutlined className="grid-icon"/></Link></span>
                  }
                </div>
            </div>
            <Drawer title={openedPopup ? (openedPopup.component.charAt(0).toUpperCase() + openedPopup.component.slice(1)) : ''} className="grid-popup-form" placement="right" visible={visible} width={'90%'} onClose={closePopup}>
              {openedPopup && getPopupContent()}
            </Drawer>
            <Table
             size="small"
             className="grid-content"
             rowSelection={props.gridProperties.options && props.gridProperties.options.hideGridSelection ? null : {
                type: 'checkbox',
                ...rowSelection,
                preserveSelectedRowKeys: true,
                selectedRowKeys: gridSelectedRowKeys
              }}
              onChange={onChange}
              columns={columns}
              loading={(loading || colLoading) ? true : false}
              dataSource={props.data}
              scroll={{ y: 400 }}
              summary={props.pinnedRows ? (pageData) => {
                return (
                  <Table.Summary fixed>
                  <Table.Summary.Row style={{textAlign: 'right'}}>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    {columns.map((col,key) => {
                      return <Table.Summary.Cell>
                        {props.pinnedRows[0][col['dataIndex']]}
                      </Table.Summary.Cell>
                  
                    })}
                  </Table.Summary.Row>
                  </Table.Summary>
                );
              } : null}
              pagination={{
                total: props.totalRecords,
                current: currentPage,
                pageSize: pageSize,
                showSizeChanger:false,
                size: 'small',
                showTotal: (total,range) => window.innerWidth >= 767 && `${plainTranslate(props.settings.locale, 'Records per page')}: ${pageSize} `
              }}
            />
            <div className="grid-total-records">{plainTranslate(props.settings.locale, 'Total records')}: {props.totalRecords}</div>
        </div>
    </div>
    );
};

const mapStateToProps = state => ({
  settings: state.settings,
  gridProperties: state.gridProperties,
  data: state.gridData.data,
  pinnedRows: state.gridData.pinnedRows,
  pages: state.gridData.pages,
  totalRecords: state.gridData.totalRecords
});

const mapDispatchToProps = dispatch => ({
  gridV2FetchData: (route, filters, signal) => dispatch(gridV2FetchData(route, filters, signal)),
  onGridMountV2: (route, data) => dispatch(onGridMountV2(route,data)),
  onGridV2ExportClick: (route, filters, template, data, cols, selectAll) => dispatch(onGridV2ExportClick(route, filters, template, data, cols, selectAll)),
  onCellActionTrigger: (button, node, route) => dispatch(onCellActionTrigger(button, node, route)),
  onConfirmDialogShow: () => dispatch({ type: GRID_CONFIRM_DIALOG_SHOW }),
  onConfirmActionTrigger: (action) => dispatch(onConfirmActionTrigger(action)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridV2);