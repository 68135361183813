export default function getGridV2Routes(db) {
    return [
        {
            title: ['Tasks', '›', 'List'],
            path: '/:db/tasks-v2',
            gridRoute: '/api/grid/settings/grid-properties/tasks',
            gridDataRoute: '/api/grid/settings/grid-data/tasks',
            gridExportRoute: '/api/grid/tasks/export/',
            gridSaveRoute: '/api/grid/tasks/save'
        },
        {
            title: ['Sales', '›', 'List'],
            path: '/:db/sales-v2',
            gridRoute: '/api/grid/settings/grid-properties/sales',
            gridDataRoute: '/api/grid/settings/grid-data/sales',
            gridExportRoute: '/api/grid/sales/export/',
            gridSaveRoute: '/api/grid/sales/save'
        },
        {
            title: ['Inventory requests', '›', 'List'],
            path: '/:db/inventory-request-v2',
            gridRoute: '/api/grid/settings/grid-properties/inventory-request',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-request',
            gridExportRoute: '/api/grid/inventory-request/export/',
            gridSaveRoute: '/api/grid/inventory-request/save'
        },
        {
            title: ['Location places', '›', 'List'],
            path: '/:db/administration/location-places',
            gridRoute: '/api/grid/settings/grid-properties/location-places',
            gridDataRoute: '/api/grid/settings/grid-data/location-places',
            gridExportRoute: '/api/grid/location-places/export/',
            gridSaveRoute: '/api/grid/location-places/save'
        },
        {
            title: ['Inventory places', '›', 'List'],
            path: '/:db/inventory-places',
            gridRoute: '/api/grid/settings/grid-properties/inventory-places',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-places',
            gridExportRoute: '/api/grid/inventory-places/export/',
            gridSaveRoute: '/api/grid/inventory-places/save'
        },
        {
            title: ['Projects', '›', 'List'],
            path: '/:db/projects-v2',
            gridRoute: '/api/grid/settings/grid-properties/projects',
            gridDataRoute: '/api/grid/settings/grid-data/projects',
            gridExportRoute: '/api/grid/projects/export/',
            gridSaveRoute: '/api/grid/projects/save'
        },
        {
            title: ['Messages', '›', 'List'],
            path: '/:db/messages',
            gridRoute: '/api/grid/settings/grid-properties/messages',
            gridDataRoute: '/api/grid/settings/grid-data/messages',
            gridExportRoute: '/api/grid/messages/export/',
            gridSaveRoute: '/api/grid/messages/save'
        },
        {
            title: ['Integrations'],
            path: '/:db/settings/integrations',
            gridRoute: '/api/grid/settings/grid-properties/integrations',
            gridDataRoute: '/api/grid/settings/grid-data/integrations',
            gridExportRoute: '/api/grid/integrations/export/',
            gridSaveRoute: '/api/grid/integrations/save'
        },
        {
            title: ['Log Time'],
            path: '/:db/log-time-v2',
            gridRoute: '/api/grid/settings/grid-properties/log-time',
            gridDataRoute: '/api/grid/settings/grid-data/log-time',
            gridExportRoute: '/api/grid/log-time/export/',
            gridSaveRoute: '/api/grid/log-time/save'
        },
        {
            title: ['Items'],
            path: '/:db/administration/items-v2',
            gridRoute: '/api/grid/settings/grid-properties/items',
            gridDataRoute: '/api/grid/settings/grid-data/items',
            gridExportRoute: '/api/grid/items/export/',
            gridSaveRoute: '/api/grid/items/save',
            exportTemplates: ['Items', 'Item Attributes','Item Measures', 'Item Locations'],
            exportTemplateRoute: '/api/export/import-data/items'
        },
        {
            title: ['Accounts'],
            path: '/:db/accounts-v2/:refs',
            gridRoute: '/api/grid/settings/grid-properties/accounts',
            gridDataRoute: '/api/grid/settings/grid-data/accounts',
            gridExportRoute: '/api/grid/accounts/export/',
            gridSaveRoute: '/api/grid/accounts/save'
        },
        {
            title: ['Deals'],
            path: '/:db/deals-v2',
            gridRoute: '/api/grid/settings/grid-properties/deals',
            gridDataRoute: '/api/grid/settings/grid-data/deals',
            gridExportRoute: '/api/grid/deals/export/',
            gridSaveRoute: '/api/grid/deals/save'
        },
        {
            title: ['Deals details'],
            path: '/:db/deal/details-v2',
            gridRoute: '/api/grid/settings/grid-properties/deal-details',
            gridDataRoute: '/api/grid/settings/grid-data/deal-details',
            gridExportRoute: '/api/grid/deal-details/export/',
            gridSaveRoute: '/api/grid/deal-details/save'
        },
        {
            title: ['Assets'],
            path: '/:db/accounting/asset-v2',
            gridRoute: '/api/grid/settings/grid-properties/asset',
            gridDataRoute: '/api/grid/settings/grid-data/asset',
            gridExportRoute: '/api/grid/asset/export/',
            gridSaveRoute: '/api/grid/asset/save'
        },
        {
            title: ['Ledger'],
            path: '/:db/accounting/ledger-v2',
            gridRoute: '/api/grid/settings/grid-properties/ledger',
            gridDataRoute: '/api/grid/settings/grid-data/ledger',
            gridExportRoute: '/api/grid/ledger/export/',
            gridSaveRoute: '/api/grid/ledger/save'
        },
        {
            title: ['Ledger details'],
            path: '/:db/accounting/ledger-details-v2',
            gridRoute: '/api/grid/settings/grid-properties/ledgerLine',
            gridDataRoute: '/api/grid/settings/grid-data/ledgerLine',
            gridExportRoute: '/api/grid/ledgerLine/export/',
            gridSaveRoute: '/api/grid/ledgerLine/save'
        },
        {
            title: ['Chart of accounts'],
            path: '/:db/accounting/chart-of-accounts-v2',
            gridRoute: '/api/grid/settings/grid-properties/chartOfAccount',
            gridDataRoute: '/api/grid/settings/grid-data/chartOfAccount',
            gridExportRoute: '/api/grid/chartOfAccount/export/',
            gridSaveRoute: '/api/grid/chartOfAccount/save'
        },
        {
            title: ['Journal'],
            path: '/:db/accounting/journal-types-v2',
            gridRoute: '/api/grid/settings/grid-properties/journal',
            gridDataRoute: '/api/grid/settings/grid-data/journal',
            gridExportRoute: '/api/grid/journal/export/',
            gridSaveRoute: '/api/grid/journal/save'
        },
        {
            title: ['Vat sales', '›', 'List'],
            path: '/:db/vat-sales-v2',
            gridRoute: '/api/grid/settings/grid-properties/vatSales',
            gridDataRoute: '/api/grid/settings/grid-data/vatSales',
            gridExportRoute: '/api/grid/vatSales/export/',
            gridSaveRoute: '/api/grid/vatSales/save'
        },
        {
            title: ['Vat purchase', '›', 'List'],
            path: '/:db/vat-purchase-v2',
            gridRoute: '/api/grid/settings/grid-properties/vatPurchase',
            gridDataRoute: '/api/grid/settings/grid-data/vatPurchase',
            gridExportRoute: '/api/grid/vatPurchase/export/',
            gridSaveRoute: '/api/grid/vatPurchase/save'
        },
        {
            title: ['Expenses', '›', 'List'],
            path: '/:db/expenses-v2',
            gridRoute: '/api/grid/settings/grid-properties/expenses',
            gridDataRoute: '/api/grid/settings/grid-data/expenses',
            gridExportRoute: '/api/grid/expenses/export/',
            gridSaveRoute: '/api/grid/expenses/save'
        },
        {

            title: ['Receipts', '›', 'List'],
            path: '/:db/inventory-v2',
            gridRoute: '/api/grid/settings/grid-properties/inventory',
            gridDataRoute: '/api/grid/settings/grid-data/inventory',
            gridExportRoute: '/api/grid/inventory/export/',
            gridSaveRoute: '/api/grid/inventory/save'
        },
        {
            title: ['Delivery Methods', '›', 'List'],
            path: '/:db/administration/delivery-methods',
            gridRoute: '/api/grid/settings/grid-properties/delivery-methods',
            gridDataRoute: '/api/grid/settings/grid-data/delivery-methods',
            gridExportRoute: '/api/grid/delivery-methods/export/',
            gridSaveRoute: '/api/grid/delivery-methods/save'
        },
        {
            title: ['Billing data', '›', 'List'],
            path: '/:db/administration/billing-data-v2',
            gridRoute: '/api/grid/settings/grid-properties/billing-data',
            gridDataRoute: '/api/grid/settings/grid-data/billing-data',
            gridExportRoute: '/api/grid/billing-data/export/',
            gridSaveRoute: '/api/grid/billing-data/save'
        },
        {
            title: ['Addresses', '›', 'List'],
            path: '/:db/administration/address-v2',
            gridRoute: '/api/grid/settings/grid-properties/address',
            gridDataRoute: '/api/grid/settings/grid-data/address',
            gridExportRoute: '/api/grid/address/export/',
            gridSaveRoute: '/api/grid/address/save'
        },
        {
            title: ['Payment term', '›', 'List'],
            path: '/:db/administration/payment-term-v2',
            gridRoute: '/api/grid/settings/grid-properties/payment-term',
            gridDataRoute: '/api/grid/settings/grid-data/payment-term',
            gridExportRoute: '/api/grid/payment-term/export/',
            gridSaveRoute: '/api/grid/payment-term/save'
        },
        {
            title: ['Region', '›', 'List'],
            path: '/:db/administration/region-v2',
            gridRoute: '/api/grid/settings/grid-properties/region',
            gridDataRoute: '/api/grid/settings/grid-data/region',
            gridExportRoute: '/api/grid/region/export/',
            gridSaveRoute: '/api/grid/region/save'
        },
        {
            title: ['Pricelists', '›', 'List'],
            path: '/:db/administration/pricing-pricelists',
            gridRoute: '/api/grid/settings/grid-properties/pricing-pricelists',
            gridDataRoute: '/api/grid/settings/grid-data/pricing-pricelists',
            gridExportRoute: '/api/grid/pricing-pricelists/export/',
            gridSaveRoute: '/api/grid/pricing-pricelists/save'
        },
        {
            title: ['Discounts', '›', 'List'],
            path: '/:db/administration/pricing-discounts',
            gridRoute: '/api/grid/settings/grid-properties/pricing-discounts',
            gridDataRoute: '/api/grid/settings/grid-data/pricing-discounts',
            gridExportRoute: '/api/grid/pricing-discounts/export/',
            gridSaveRoute: '/api/grid/pricing-discounts/save'
        },
        {
            title: ['Item Group', '›', 'List'],
            path: '/:db/administration/item-groups',
            gridRoute: '/api/grid/settings/grid-properties/item-groups',
            gridDataRoute: '/api/grid/settings/grid-data/item-groups',
            gridExportRoute: '/api/grid/item-groups/export/',
            gridSaveRoute: '/api/grid/item-groups/save'
        },
        {
            title: ['Pricing Group', '›', 'List'],
            path: '/:db/administration/pricing-groups',
            gridRoute: '/api/grid/settings/grid-properties/pricing-groups',
            gridDataRoute: '/api/grid/settings/grid-data/pricing-groups',
            gridExportRoute: '/api/grid/pricing-groups/export/',
            gridSaveRoute: '/api/grid/pricing-groups/save'
        },
        {
            title: ['Payments', '›', 'List'],
            path: '/:db/payments-v2',
            gridRoute: '/api/grid/settings/grid-properties/payments',
            gridDataRoute: '/api/grid/settings/grid-data/payments',
            gridExportRoute: '/api/grid/payments/export/',
            gridSaveRoute: '/api/grid/payments/save'
        },
        {
            title: ['Production', '›', 'List'],
            path: '/:db/production-v2',
            gridRoute: '/api/grid/settings/grid-properties/production',
            gridDataRoute: '/api/grid/settings/grid-data/production',
            gridExportRoute: '/api/grid/production/export/',
            gridSaveRoute: '/api/grid/production/save'
        },
        {
            title: ['Production Details', '›', 'List'],
            path: '/:db/production-details-v2',
            gridRoute: '/api/grid/settings/grid-properties/production-details',
            gridDataRoute: '/api/grid/settings/grid-data/production-details',
            gridExportRoute: '/api/grid/production-details/export/',
            gridSaveRoute: '/api/grid/production-details/save'
        },
        {
            title: ['Cms Content', '›', 'List'],
            path: '/:db/cms-content-v2',
            gridRoute: '/api/grid/settings/grid-properties/cms-content',
            gridDataRoute: '/api/grid/settings/grid-data/cms-content',
            gridExportRoute: '/api/grid/cms-content/export/',
            gridSaveRoute: '/api/grid/cms-content/save'
        },
        {
            title: ['Collection Lists', '›', 'List'],
            path: '/:db/cms-collection-list-v2',
            gridRoute: '/api/grid/settings/grid-properties/cms-collection-list',
            gridDataRoute: '/api/grid/settings/grid-data/cms-collection-list',
            gridExportRoute: '/api/grid/cms-collection-list/export/',
            gridSaveRoute: '/api/grid/cms-collection-list/save'
        },
        {
            title: ['Collection Elements', '›', 'List'],
            path: '/:db/cms-collection-element-v2',
            gridRoute: '/api/grid/settings/grid-properties/cms-collection-element',
            gridDataRoute: '/api/grid/settings/grid-data/cms-collection-element',
            gridExportRoute: '/api/grid/cms-collection-element/export/',
            gridSaveRoute: '/api/grid/cms-collection-element/save'
        },
        {
            title: ['Attributes', '›', 'List'],
            path: '/:db/administration/attributes',
            gridRoute: '/api/grid/settings/grid-properties/attributes',
            gridDataRoute: '/api/grid/settings/grid-data/attributes',
            gridExportRoute: '/api/grid/attributes/export/',
            gridSaveRoute: '/api/grid/attributes/save'
        },
        {
            title: ['Attribute Categories', '›', 'List'],
            path: '/:db/administration/attribute-categories',
            gridRoute: '/api/grid/settings/grid-properties/attribute-categories',
            gridDataRoute: '/api/grid/settings/grid-data/attribute-categories',
            gridExportRoute: '/api/grid/attribute-categories/export/',
            gridSaveRoute: '/api/grid/attribute-categories/save'
        },
        {
            title: ['Attribute Groups', '›', 'List'],
            path: '/:db/administration/attribute-groups',
            gridRoute: '/api/grid/settings/grid-properties/attribute-groups',
            gridDataRoute: '/api/grid/settings/grid-data/attribute-groups',
            gridExportRoute: '/api/grid/attribute-groups/export/',
            gridSaveRoute: '/api/grid/attribute-groups/save'
        },
        {
            title: ['Menus', '›', 'List'],
            path: '/:db/cms-menu-v2',
            gridRoute: '/api/grid/settings/grid-properties/cms-menu',
            gridDataRoute: '/api/grid/settings/grid-data/cms-menu',
            gridExportRoute: '/api/grid/cms-menu/export/',
            gridSaveRoute: '/api/grid/cms-menu/save'
        },
        {
            title: ['Language', '›', 'List'],
            path: '/:db/administration/languages-v2',
            gridRoute: '/api/grid/settings/grid-properties/languages',
            gridDataRoute: '/api/grid/settings/grid-data/languages',
            gridExportRoute: '/api/grid/languages/export/',
            gridSaveRoute: '/api/grid/languages/save'
        },
        {
            title: ['Translations', '›', 'List'],
            path: '/:db/administration/translations-v2',
            gridRoute: '/api/grid/settings/grid-properties/translations',
            gridDataRoute: '/api/grid/settings/grid-data/translations',
            gridExportRoute: '/api/grid/translations/export/',
            gridSaveRoute: '/api/grid/translations/save',
            exportTemplates: ['Translations'],
            exportTemplateRoute: '/api/export/import-data/translations'
        },
        {
            title: ['Cms Content Route', '›', 'List'],
            path: '/:db/cms-content-route-v2/:refs',
            gridRoute: '/api/grid/settings/grid-properties/cms-content-route',
            gridDataRoute: '/api/grid/settings/grid-data/cms-content-route',
            gridExportRoute: '/api/grid/cms-content-route/export/',
            gridSaveRoute: '/api/grid/cms-content-route/save'
        },
        {
            title: ['Cms Component', '›', 'List'],
            path: '/:db/cms-component-v2',
            gridRoute: '/api/grid/settings/grid-properties/cms-component',
            gridDataRoute: '/api/grid/settings/grid-data/cms-component',
            gridExportRoute: '/api/grid/cms-component/export/',
            gridSaveRoute: '/api/grid/cms-component/save'
        },
        {
            title: ['Project Boards', '›', 'List'],
            path: '/:db/project-boards',
            gridRoute: '/api/grid/settings/grid-properties/project-boards',
            gridDataRoute: '/api/grid/settings/grid-data/project-boards',
            gridExportRoute: '/api/grid/project-boards/export/',
            gridSaveRoute: '/api/grid/project-boards/save'
        },
        {
            title: ['Content Data', '›', 'List'],
            path: '/:db/content-data-v2/:refs',
            gridRoute: '/api/grid/settings/grid-properties/content-data',
            gridDataRoute: '/api/grid/settings/grid-data/content-data',
            gridExportRoute: '/api/grid/content-data/export/',
            gridSaveRoute: '/api/grid/content-data/save'
        },
        {
            title: ['Profit & Loss'],
            path: '/:db/accounting/pl-report',
            gridRoute: '/api/grid/settings/grid-properties/accounting-pl-report',
            gridDataRoute: '/api/grid/settings/grid-data/accounting-pl-report',
            gridExportRoute: '/api/grid/accounting-pl-report/export/',
            gridSaveRoute: '/api/grid/accounting-pl-report/save'
        },
        {
            title: ['Balance Sheet'],
            path: '/:db/accounting/bs-report',
            gridRoute: '/api/grid/settings/grid-properties/accounting-bs-report',
            gridDataRoute: '/api/grid/settings/grid-data/accounting-bs-report',
            gridExportRoute: '/api/grid/accounting-bs-report/export/',
            gridSaveRoute: '/api/grid/accounting-bs-report/save'
        },
        {
            title: ['Account Payable / Receivables'],
            path: '/:db/accounting/ledger-account-report',
            gridRoute: '/api/grid/settings/grid-properties/accounting-ledger-account-report',
            gridDataRoute: '/api/grid/settings/grid-data/accounting-ledger-account-report',
            gridExportRoute: '/api/grid/accounting-ledger-account-report/export/',
            gridSaveRoute: '/api/grid/accounting-ledger-account-report/save'
        },
        {
            title: ['Warranty'],
            path: '/:db/warranties-v2',
            gridRoute: '/api/grid/settings/grid-properties/warranties',
            gridDataRoute: '/api/grid/settings/grid-data/warranties',
            gridExportRoute: '/api/grid/warranties/export/',
            gridSaveRoute: '/api/grid/warranties/save'
        },
        {
            title: ['Imports'],
            path: '/:db/administration/imports',
            gridRoute: '/api/grid/settings/grid-properties/imports',
            gridDataRoute: '/api/grid/settings/grid-data/imports',
            gridExportRoute: '/api/grid/imports/export/',
            gridSaveRoute: '/api/grid/imports/save'
        },
        {
            title: ['Contracts'],
            path: '/:db/contracts-v2',
            gridRoute: '/api/grid/settings/grid-properties/contracts',
            gridDataRoute: '/api/grid/settings/grid-data/contracts',
            gridExportRoute: '/api/grid/contracts/export/',
            gridSaveRoute: '/api/grid/contracts/save'
        },
        {
            title: ['Activities'],
            path: '/:db/activities-v2',
            gridRoute: '/api/grid/settings/grid-properties/activities',
            gridDataRoute: '/api/grid/settings/grid-data/activities',
            gridExportRoute: '/api/grid/activities/export/',
            gridSaveRoute: '/api/grid/activities/save'
        },
        {
            title: ['Segments'],
            path: '/:db/segments-v2',
            gridRoute: '/api/grid/settings/grid-properties/segments',
            gridDataRoute: '/api/grid/settings/grid-data/segments',
            gridExportRoute: '/api/grid/segments/export/',
            gridSaveRoute: '/api/grid/segments/save'
        },
        {
            title: ['Form Types'],
            path: '/:db/form-types-v2',
            gridRoute: '/api/grid/settings/grid-properties/form-types',
            gridDataRoute: '/api/grid/settings/grid-data/form-types',
            gridExportRoute: '/api/grid/form-types/export/',
            gridSaveRoute: '/api/grid/form-types/save'
        },
        {
            title: ['Users'],
            path: '/:db/administration/users-v2/:refs',
            gridRoute: '/api/grid/settings/grid-properties/users',
            gridDataRoute: '/api/grid/settings/grid-data/users',
            gridExportRoute: '/api/grid/users/export/',
            gridSaveRoute: '/api/grid/users/save'
        },
        {
            title: ['Currencies'],
            path: '/:db/administration/currencies-v2',
            gridRoute: '/api/grid/settings/grid-properties/currencies',
            gridDataRoute: '/api/grid/settings/grid-data/currencies',
            gridExportRoute: '/api/grid/currencies/export/',
            gridSaveRoute: '/api/grid/currencies/save'
        },
        {
            title: ['Currency rates'],
            path: '/:db/administration/currency-rates-v2',
            gridRoute: '/api/grid/settings/grid-properties/currency-rates',
            gridDataRoute: '/api/grid/settings/grid-data/currency-rates',
            gridExportRoute: '/api/grid/currency-rates/export/',
            gridSaveRoute: '/api/grid/currency-rates/save'
        },
        {
            title: ['Activities Types'],
            path: '/:db/administration/events-v2',
            gridRoute: '/api/grid/settings/grid-properties/events',
            gridDataRoute: '/api/grid/settings/grid-data/events',
            gridExportRoute: '/api/grid/events/export/',
            gridSaveRoute: '/api/grid/events/save'
        },
        {
            title: ['Taxes'],
            path: '/:db/administration/taxes-v2',
            gridRoute: '/api/grid/settings/grid-properties/taxes',
            gridDataRoute: '/api/grid/settings/grid-data/taxes',
            gridExportRoute: '/api/grid/taxes/export/',
            gridSaveRoute: '/api/grid/taxes/save'
        },
        {
            title: ['Taxes Class'],
            path: '/:db/administration/taxes-class-v2',
            gridRoute: '/api/grid/settings/grid-properties/taxes-class',
            gridDataRoute: '/api/grid/settings/grid-data/taxes-class',
            gridExportRoute: '/api/grid/taxes-class/export/',
            gridSaveRoute: '/api/grid/taxes-class/save'
        },
        {
            title: ['Batches'],
            path: '/:db/administration/batches-v2',
            gridRoute: '/api/grid/settings/grid-properties/batches',
            gridDataRoute: '/api/grid/settings/grid-data/batches',
            gridExportRoute: '/api/grid/batches/export/',
            gridSaveRoute: '/api/grid/batches/save'
        },
        {
            title: ['Measures'],
            path: '/:db/administration/measures-v2',
            gridRoute: '/api/grid/settings/grid-properties/measures',
            gridDataRoute: '/api/grid/settings/grid-data/measures',
            gridExportRoute: '/api/grid/measures/export/',
            gridSaveRoute: '/api/grid/measures/save'
        },
        {
            title: ['Item Categories'],
            path: '/:db/administration/item-categories-v2',
            gridRoute: '/api/grid/settings/grid-properties/item-categories',
            gridDataRoute: '/api/grid/settings/grid-data/item-categories',
            gridExportRoute: '/api/grid/item-categories/export/',
            gridSaveRoute: '/api/grid/item-categories/save'
        },
        {
            title: ['Locations'],
            path: '/:db/administration/locations-v2',
            gridRoute: '/api/grid/settings/grid-properties/locations',
            gridDataRoute: '/api/grid/settings/grid-data/locations',
            gridExportRoute: '/api/grid/locations/export/',
            gridSaveRoute: '/api/grid/locations/save'
        },
        {
            title: ['Channels'],
            path: '/:db/administration/channels-v2',
            gridRoute: '/api/grid/settings/grid-properties/channels',
            gridDataRoute: '/api/grid/settings/grid-data/channels',
            gridExportRoute: '/api/grid/channels/export/',
            gridSaveRoute: '/api/grid/channels/save'
        },
        {
            title: ['Devices'],
            path: '/:db/administration/devices-v2',
            gridRoute: '/api/grid/settings/grid-properties/devices',
            gridDataRoute: '/api/grid/settings/grid-data/devices',
            gridExportRoute: '/api/grid/devices/export/',
            gridSaveRoute: '/api/grid/devices/save'
        },
        {
            title: ['Workplaces'],
            path: '/:db/administration/workplaces-v2',
            gridRoute: '/api/grid/settings/grid-properties/workplaces',
            gridDataRoute: '/api/grid/settings/grid-data/workplaces',
            gridExportRoute: '/api/grid/workplaces/export/',
            gridSaveRoute: '/api/grid/workplaces/save'
        },
        {
            title: ['Templates Documents'],
            path: '/:db/administration/template-documents-v2',
            gridRoute: '/api/grid/settings/grid-properties/template-documents',
            gridDataRoute: '/api/grid/settings/grid-data/template-documents',
            gridExportRoute: '/api/grid/template-documents/export/',
            gridSaveRoute: '/api/grid/template-documents/save'
        },
        {
            title: ['Templates Email'],
            path: '/:db/administration/template-email-v2',
            gridRoute: '/api/grid/settings/grid-properties/template-email',
            gridDataRoute: '/api/grid/settings/grid-data/template-email',
            gridExportRoute: '/api/grid/template-email/export/',
            gridSaveRoute: '/api/grid/template-email/save'
        },
        {
            title: ['Templates SMS'],
            path: '/:db/administration/template-sms-v2',
            gridRoute: '/api/grid/settings/grid-properties/template-sms',
            gridDataRoute: '/api/grid/settings/grid-data/template-sms',
            gridExportRoute: '/api/grid/template-sms/export/',
            gridSaveRoute: '/api/grid/template-sms/save'
        },
        {
            title: ['Templates Export'],
            path: '/:db/administration/template-export-v2',
            gridRoute: '/api/grid/settings/grid-properties/template-export',
            gridDataRoute: '/api/grid/settings/grid-data/template-export',
            gridExportRoute: '/api/grid/template-export/export/',
            gridSaveRoute: '/api/grid/template-export/save'
        },
        {
            title: ['Calendars'],
            path: '/:db/administration/calendars-v2',
            gridRoute: '/api/grid/settings/grid-properties/calendars',
            gridDataRoute: '/api/grid/settings/grid-data/calendars',
            gridExportRoute: '/api/grid/calendars/export/',
            gridSaveRoute: '/api/grid/calendars/save'
        },
        {
            title: ['Calendar Type'],
            path: '/:db/administration/calendar-type-v2',
            gridRoute: '/api/grid/settings/grid-properties/calendar-type',
            gridDataRoute: '/api/grid/settings/grid-data/calendar-type',
            gridExportRoute: '/api/grid/calendar-type/export/',
            gridSaveRoute: '/api/grid/calendar-type/save'
        },
        {
            title: ['Calendar Event'],
            path: '/:db/administration/calendar-event-v2',
            gridRoute: '/api/grid/settings/grid-properties/calendar-event',
            gridDataRoute: '/api/grid/settings/grid-data/calendar-event',
            gridExportRoute: '/api/grid/calendar-event/export/',
            gridSaveRoute: '/api/grid/calendar-event/save'
        },
        {
            title: ['CMS Inventory'],
            path: '/:db/cms-inventory',
            gridRoute: '/api/grid/settings/grid-properties/cms-inventory',
            gridDataRoute: '/api/grid/settings/grid-data/cms-inventory',
            gridExportRoute: '/api/grid/cms-inventory/export/',
            gridSaveRoute: '/api/grid/cms-inventory/save'
        },
        {
            title: ['Pricelist details'],
            path: '/:db/administration/pricelist-details',
            gridRoute: '/api/grid/settings/grid-properties/pricelist-details',
            gridDataRoute: '/api/grid/settings/grid-data/pricelist-details',
            gridExportRoute: '/api/grid/pricelist-details/export/',
            gridSaveRoute: '/api/grid/pricelist-details/save'
        },
        {
            title: ['Minimal Quantity'],
            path: '/:db/administration/item/min-quantity-report',
            gridRoute: '/api/grid/settings/grid-properties/min-quantity',
            gridDataRoute: '/api/grid/settings/grid-data/min-quantity',
            gridExportRoute: '/api/grid/min-quantity/export/',
            gridSaveRoute: '/api/grid/min-quantity/save'
        },
        {
            title: ['Payment methods', '›', 'List'],
            path: '/:db/administration/payment-methods-v2',
            gridRoute: '/api/grid/settings/grid-properties/payment-methods',
            gridDataRoute: '/api/grid/settings/grid-data/payment-methods',
            gridExportRoute: '/api/grid/payment-methods/export/',
            gridSaveRoute: '/api/grid/payment-methods/save'
        },
        {
            title: ['incoterms', '›', 'List'],
            path: '/:db/administration/incoterms-v2',
            gridRoute: '/api/grid/settings/grid-properties/incoterms',
            gridDataRoute: '/api/grid/settings/grid-data/incoterms',
            gridExportRoute: '/api/grid/incoterms/export/',
            gridSaveRoute: '/api/grid/incoterms/save'
        },
        {
            title: ['Stock on Hand'],
            path: '/:db/data-source/stock-on-hand-v2',
            gridRoute: '/api/grid/settings/grid-properties/stock-on-hand',
            gridDataRoute: '/api/grid/settings/grid-data/stock-on-hand',
            gridExportRoute: '/api/grid/stock-on-hand/export/',
            gridSaveRoute: '/api/grid/stock-on-hand/save'
        },
        {
            title: ['Account Payables'],
            path: '/:db/data-source/account-payables-v2',
            gridRoute: '/api/grid/settings/grid-properties/account-payables',
            gridDataRoute: '/api/grid/settings/grid-data/account-payables',
            gridExportRoute: '/api/grid/account-payables/export/',
            gridSaveRoute: '/api/grid/account-payables/save'
        },
        {
            title: ['Account Receivables'],
            path: '/:db/data-source/account-receivables-v2',
            gridRoute: '/api/grid/settings/grid-properties/account-receivables',
            gridDataRoute: '/api/grid/settings/grid-data/account-receivables',
            gridExportRoute: '/api/grid/account-receivables/export/',
            gridSaveRoute: '/api/grid/account-receivables/save'
        },
        {
            title: ['Aging Analysis'],
            path: '/:db/data-source/aging-analysis-v2',
            gridRoute: '/api/grid/settings/grid-properties/aging-analysis',
            gridDataRoute: '/api/grid/settings/grid-data/aging-analysis',
            gridExportRoute: '/api/grid/aging-analysis/export/',
            gridSaveRoute: '/api/grid/aging-analysis/save'
        },
        {
            title: ['Item Company', '›', 'List'],
            path: '/:db/administration/item-company',
            gridRoute: '/api/grid/settings/grid-properties/item-company',
            gridDataRoute: '/api/grid/settings/grid-data/item-company',
            gridExportRoute: '/api/grid/item-company/export/',
            gridSaveRoute: '/api/grid/item-company/save'
        },
        {
            title: ['Deal Data', '›', 'List'],
            path: '/:db/data-source/deal-data',
            gridRoute: '/api/grid/settings/grid-properties/dealData',
            gridDataRoute: '/api/grid/settings/grid-data/dealData',
            gridExportRoute: '/api/grid/dealData/export/',
            gridSaveRoute: '/api/grid/dealData/save'
        },
        {
            title: ['Availability by Date'],
            path: '/:db/data-source/availability-by-date-v2',
            gridRoute: '/api/grid/settings/grid-properties/availability-by-date',
            gridDataRoute: '/api/grid/settings/grid-data/availability-by-date',
            gridExportRoute: '/api/grid/availability-by-date/export/',
            gridSaveRoute: '/api/grid/availability-by-date/save'
        },
        {
            title: ['Inventory Movement'],
            path: '/:db/data-source/inventory-movement-v2',
            gridRoute: '/api/grid/settings/grid-properties/inventory-movement',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-movement',
            gridExportRoute: '/api/grid/inventory-movement/export/',
            gridSaveRoute: '/api/grid/inventory-movement/save'
        },
        {
            title: ['Item Bill of Material'],
            path: '/:db/data-source/item-bom-v2',
            gridRoute: '/api/grid/settings/grid-properties/item-bom',
            gridDataRoute: '/api/grid/settings/grid-data/item-bom',
            gridExportRoute: '/api/grid/item-bom/export/',
            gridSaveRoute: '/api/grid/item-bom/save'
        },
        {
            title: ['Production Bill of Material'],
            path: '/:db/data-source/production-bom-v2',
            gridRoute: '/api/grid/settings/grid-properties/production-bom',
            gridDataRoute: '/api/grid/settings/grid-data/production-bom',
            gridExportRoute: '/api/grid/production-bom/export/',
            gridSaveRoute: '/api/grid/production-bom/save'
        },
        {
            title: ['Reserved Quantity'],
            path: '/:db/data-source/reserved-quantity-v2',
            gridRoute: '/api/grid/settings/grid-properties/reserved-quantity',
            gridDataRoute: '/api/grid/settings/grid-data/reserved-quantity',
            gridExportRoute: '/api/grid/reserved-quantity/export/',
            gridSaveRoute: '/api/grid/reserved-quantity/save'
        },
        {
            title: ['Suppliers'],
            path: '/:db/data-source/suppliers-v2',
            gridRoute: '/api/grid/settings/grid-properties/suppliers',
            gridDataRoute: '/api/grid/settings/grid-data/suppliers',
            gridExportRoute: '/api/grid/suppliers/export/',
            gridSaveRoute: '/api/grid/suppliers/save'
        },
        {
            title: ['Profit and Loss'],
            path: '/:db/data-source/profit-loss-v2',
            gridRoute: '/api/grid/settings/grid-properties/profit-loss',
            gridDataRoute: '/api/grid/settings/grid-data/profit-loss',
            gridExportRoute: '/api/grid/profit-loss/export/',
            gridSaveRoute: '/api/grid/profit-loss/save'
        },
        {
            title: ['Trial Balance'],
            path: '/:db/data-source/trial-balance-v2',
            gridRoute: '/api/grid/settings/grid-properties/trial-balance',
            gridDataRoute: '/api/grid/settings/grid-data/trial-balance',
            gridExportRoute: '/api/grid/trial-balance/export/',
            gridSaveRoute: '/api/grid/trial-balance/save'
        },
        {
            title: ['Sale Revenue'],
            path: '/:db/data-source/sale-revenue-v2',
            gridRoute: '/api/grid/settings/grid-properties/sale-revenue',
            gridDataRoute: '/api/grid/settings/grid-data/sale-revenue',
            gridExportRoute: '/api/grid/sale-revenue/export/',
            gridSaveRoute: '/api/grid/sale-revenue/save'
        },
        {
            title: ['Stock Movement'],
            path: '/:db/data-source/stock-movement',
            gridRoute: '/api/grid/settings/grid-properties/stock-movement',
            gridDataRoute: '/api/grid/settings/grid-data/stock-movement',
            gridExportRoute: '/api/grid/stock-movement/export/',
            gridSaveRoute: '/api/grid/stock-movement/save'
        },
    ];
}
